import { Suspense } from 'react';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
import { Route, Switch } from 'react-router-dom';
import NurseStationWrapper from 'views/NurseStationTablets/NurseStationWrapper.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import PatientReportsWrapper from 'containers/Patients/PatientReportsWrapper.jsx';
import HealthSystemWrapper from 'views/HealthSystemWrapper.jsx';
import Patients from 'views/Patients/Patients.jsx';
import NursesWrapper from 'components/NursesWrapper.jsx';
import AppointmentWrapper from 'views/AppointmentWrapper.jsx';
import CallLogs from 'views/CallLogs/CallLogs.jsx';
import UsersWrapper from 'views/UsersWrapper.jsx';
import Companies from 'views/Companies.jsx';
import MyDoctors from 'views/Doctors/MyDoctors.jsx';
import MyHelloDevices from 'views/HelloDevices/MyHelloDevices.jsx';
import AvailableDoctorsWrapper from 'views/Doctors/AvailableDoctorsWrapper.jsx';
import MyVitalSignsWrapper from 'views/Patients/MyVitalSignsWrapper.jsx';
import { RouteTypes, UserRoles } from 'constants/enums.js';
import ConfigurationsWrapper from 'views/ConfigurationsWrapper.jsx';
import ConfigurationsHistory from 'views/ConfigurationsHistory.jsx';
import Teams from 'views/Teams.jsx';
import DigitalClinicianVisitWrapper from 'containers/PrimaryCare/DigitalClinician/DigitalClinicianVisitWrapper.jsx';
import DigitalClinicianCheckIn from 'views/DigitalClinicianCheckIn.jsx';
import AuditLogsWraper from 'views/AuditLogsWraper.jsx';
import WaitingRoom from 'views/WaitingRoom.jsx';
import VisitorsWrapper from 'views/VisitorsWrapper.jsx';
import RpmOnboardingPatientWrapper from 'containers/Rpm/RpmOnboardingPatientWrapper.jsx';
// import FamilyMembersChat from 'views/FamilyMembersChat/FamilyMembersChat.jsx';
import SelectPatientCompany from 'views/Onboarding/Patient/SelectPatientCompany.jsx';
import LabWaitingResultWrapper from 'containers/PrimaryCare/LaboratoryAnalysis/LabWaitingResultWrapper.jsx';
import WaitingRoomHome from 'views/WaitingRoomHome.jsx';
import InPersonVisitWrapper from 'containers/PrimaryCare/Doctor/InPersonVisitWrapper.jsx';
import CheckInHome from 'views/CheckInHome.jsx';
import PatientHomePage from 'views/PatientHomePage.jsx';
import VisitorWrapper from 'views/VisitorWrapper.jsx';
import VcpCheckIn from 'containers/PrimaryCare/LaboratoryAnalysis/VcpCheckIn.jsx';
import DoctorHomePageWrapper from 'views/Doctors/DoctorHomePageWrapper.jsx';
import Logistics from 'views/Logistics.jsx';
import DashboardWrapper from 'views/DashboardWrapper.jsx';
import QueueManagementWrapper from 'views/QueueManagement/QueueManagementWrapper.jsx';
import FamilyMemberPatientWrapper from 'containers/Rpm/FamilyMemberPatientWrapper.jsx';
import SessionsQueueWrapper from 'views/SessionsQueueWrapper.jsx';
import NursePatientsWrapper from 'components/NursePatientsWrapper.jsx';
import CareEventsDashboardWrapper from 'containers/CareEventsDashboard/CareEventsDashboardWrapper.jsx';
import MonitoringWrapper from 'views/MonitoringWrapper.jsx';
import CareNotificationsWrapper from 'views/CareNotifications/CareNotificationsWrapper.jsx';
import FleetManagementWrapper from 'views/FleetManagementWrapper.jsx';
import PatientMonitoringWrapper from 'containers/Rpm/PatientMonitoringWrapper.jsx';
import HospitalAtHomeWrapper from 'containers/HospitalAtHome/HospitalAtHomeWrapper.jsx';
import AccountSettingsWrapper from 'views/AccountSettingsWrapper.jsx';
import ConferenceCall from 'calls/Conference.jsx';
import ConferenceRouteRender from 'calls/ConferenceRouteRender.jsx';
const CallRedirect = lazyWithRetry(() => import('calls/CallRedirect.jsx'));
const Call = lazyWithRetry(() => import('views/Call.jsx'));
const Callback = lazyWithRetry(() => import('components/auth/callback.jsx'));
const Logout = lazyWithRetry(() => import('components/auth/logout.jsx'));
const LogoutCallback = lazyWithRetry(() => import('components/auth/logoutCallback.jsx'));
const PrivateRoute = lazyWithRetry(() => import('routes/privateRoute.jsx'));
const SilentRenew = lazyWithRetry(() => import('components/auth/silentRenew.jsx'));
const PatientSummaryWrapper = lazyWithRetry(() => import('components/PatientSummaryWrapper.jsx'));
const Help = lazyWithRetry(() => import('views/Help.jsx'));
const CompleteRegistration = lazyWithRetry(() => import('views/Doctors/CompleteRegistration.jsx'));
const SignsSymptoms = lazyWithRetry(() => import('views/Doctors/Partials/SignsSymptoms.jsx'));
const Recover = lazyWithRetry(() => import('views/Recover.jsx'));
const ResetPassword = lazyWithRetry(() => import('views/ResetPassword.jsx'));
const VerifyChangedEmail = lazyWithRetry(() => import('views/VerifyChangedEmail.jsx'));
const ComingSoon = lazyWithRetry(() => import('components/ComingSoon.jsx'));
const PrivacyPolicy = lazyWithRetry(() => import('views/PrivacyPolicy.jsx'));
const TermsOfUse = lazyWithRetry(() => import('views/TermsOfUse.jsx'));
const DoctorRequestsWrapper = lazyWithRetry(() => import('views/DoctorRequestsWrapper.jsx'));
const VitalSignsMonitoring = lazyWithRetry(() => import('views/VitalSignsMonitoring.jsx'));
const JoinAsGuest = lazyWithRetry(() => import('calls/views/JoinAsGuest.jsx'));
const CreateCompanyWrapper = lazyWithRetry(() => import('views/CreateCompanyWrapper.jsx'));
const ContinueAs = lazyWithRetry(() => import('containers/ContinueAs.jsx'));
const HelloLandingPage = lazyWithRetry(() => import('views/HelloLandingPage.jsx'));
const TechSpecs = lazyWithRetry(() => import('views/TechSpecs.jsx'));
const Home = lazyWithRetry(() => import('views/Partials/Home.jsx'));
const QrCode = lazyWithRetry(() => import('views/QrCode.jsx'));
const AddEditRpmProgram = lazyWithRetry(() => import('containers/Rpm/AddEditRpmProgram.jsx'));
const EHRCallPatientJoinAsGuest = lazyWithRetry(() => import('views/Partials/EHRCallPatientJoinAsGuest.jsx'));
const CallPatientOptions = lazyWithRetry(() => import('views/ehr/CallPatientOptions.jsx'));
const MonitorDevice = lazyWithRetry(() => import('views/ehr/MonitorDevice.jsx'));
const DetailedReport = lazyWithRetry(() => import('components/DashboardCharts/PatientCentric/DetailedReport.jsx'));
const CreateHealthSystem = lazyWithRetry(() => import('views/CreateHealthSystem.jsx'));
const CompleteProfile = lazyWithRetry(() => import('views/CompleteProfile.jsx'));
const SelectCountry = lazyWithRetry(() => import('views/Onboarding/Doctor/SelectCountry.jsx'));
const DoctorOnboarding = lazyWithRetry(() => import('views/Onboarding/Doctor/DoctorOnboarding.jsx'));
const UserActivation = lazyWithRetry(() => import('views/Onboarding/UserActivation.jsx'));
const CompleteCompany = lazyWithRetry(() => import('views/CompleteCompany.jsx'));
const PatientOnboarding = lazyWithRetry(() => import('views/Onboarding/Patient/PatientOnboarding.jsx'));

const DefaultRoutes = (
	<Suspense
		fallback={
			<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
				<div className='text-align-center'>
					<Loader />
				</div>
			</Grid>
		}>
		<Switch>
			<Route exact={true} path='/signin-oidc' component={Callback} />
			<Route exact={true} path='/logout' component={Logout} />
			<Route exact={true} path='/logout/callback' component={LogoutCallback} />
			<Route exact={true} path='/silent-renew' component={SilentRenew} />
			<Route exact={true} path='/recover' component={Recover} />
			<Route exact={true} path='/validate/recoverPassword' component={ResetPassword} />
			<Route exact={true} path='/validate/changeEmail' component={VerifyChangedEmail} />
			<Route path='/complete-profile' component={CompleteProfile} />
			<Route path='/privacy-policy' component={PrivacyPolicy} />
			<Route path='/terms-of-use' component={TermsOfUse} />
			<Route path='/call-session/:conferenceId/invitation/:invitationSecret' component={JoinAsGuest} />
			<PrivateRoute
				roles={[UserRoles.GUEST]}
				path='/virtual-session/:conferenceId/invitation/:invitationSecret/:refToken'
				component={JoinAsGuest}
			/>
			<PrivateRoute
				roles={[UserRoles.GUEST]}
				path='/virtual-session/:startConferenceId/:callType/:deviceId/:name'
				component={JoinAsGuest}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/virtual-care-session/:conferenceId/invitation/:invitationSecret/:refToken/:isDirect'
				component={EHRCallPatientJoinAsGuest}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/virtual-care-session/:startConferenceId/:callType/:deviceId/:isDirect'
				component={EHRCallPatientJoinAsGuest}
			/>
			<Route path='/help' component={Help} />
			<Route path='/qrcode' component={QrCode} />
			<Route exact={true} path='/' component={Home} />
			<PrivateRoute exact={true} roles={[UserRoles.PATIENT]} path='/patient-home' component={PatientHomePage} />
			<PrivateRoute exact={true} roles={[UserRoles.DOCTOR]} path='/doctor-home' component={DoctorHomePageWrapper} />
			<PrivateRoute exact={true} roles={[UserRoles.NURSE]} path='/nurse-home' component={VcpCheckIn} />
			<Route exact={true} path='/hello/specs' component={TechSpecs} />
			<Route exact={true} path='/hello' component={HelloLandingPage} />
			<PrivateRoute exact path='/continue-as' component={ContinueAs} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId/:tab/:subTab' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId/:tab' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/hospital-at-home' component={HospitalAtHomeWrapper} />
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/digital-clinician/patients' component={PatientReportsWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurses/:id' component={NursesWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurses' component={NursesWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurse-patients/:id' component={NursePatientsWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurse-patients' component={NursePatientsWrapper} />
			{/* <PrivateRoute roles={[UserRoles.VISITOR, UserRoles.NURSE]} path='/family-members-chat' component={FamilyMembersChat} /> */}
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/health-data/:subTab' component={MyVitalSignsWrapper} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/health-data' component={MyVitalSignsWrapper} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/recent/:id' component={MyDoctors} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/recent' component={MyDoctors} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/summary' component={PatientSummaryWrapper} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/select-doctor' component={DoctorRequestsWrapper} />
			<PrivateRoute
				exact={true}
				roles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}
				path='/audit-logs'
				component={AuditLogsWraper}
			/>
			<PrivateRoute
				exact={true}
				roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
				path='/call-patient-options'
				component={CallPatientOptions}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital/department/:department/floor/:floor/room/:room'
				component={HealthSystemWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital/department/:department/floor/:floor'
				component={HealthSystemWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital/department/:department'
				component={HealthSystemWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital'
				component={HealthSystemWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system'
				component={HealthSystemWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/visitors/:hospital/department/:department/floor/:floor/room/:room'
				component={VisitorsWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/visitors/:hospital/department/:department/floor/:floor'
				component={VisitorsWrapper}
			/>
			<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital/department/:department' component={VisitorsWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital' component={VisitorsWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors' component={VisitorsWrapper} />
			<PrivateRoute roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]} path='/monitoring' component={MonitoringWrapper} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
				type={RouteTypes.DEFAULT}
				path='/waiting-room'
				component={WaitingRoom}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR]}
				type={RouteTypes.DEFAULT}
				path='/sessions-queue'
				component={SessionsQueueWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]}
				path='/monitor-device/:deviceId'
				component={MonitorDevice}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.PATIENT]} path='/appointments' component={AppointmentWrapper} />
			<PrivateRoute roles={[UserRoles.ADMIN]} path='/create-health-system' component={CreateHealthSystem} />
			<PrivateRoute
				roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/dashboard/detailed-report'
				component={DetailedReport}
			/>
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/fleet-management' component={FleetManagementWrapper} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/nurse-station' component={NurseStationWrapper} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/dashboard' component={DashboardWrapper} />
			<PrivateRoute
				roles={[UserRoles.ADMIN, UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN]}
				path='/users'
				component={UsersWrapper}
			/>
			<PrivateRoute roles={[UserRoles.SUPER_ADMIN]} path='/companies' component={Companies} />
			<PrivateRoute roles={[UserRoles.SUPER_ADMIN]} path='/create-company' component={CreateCompanyWrapper} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/call-logs' component={CallLogs} />
			<PrivateRoute
				roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]}
				exact
				path='/configurations'
				component={ConfigurationsWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.ADMIN, UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN]}
				exact
				path='/configurations/:id/type/:type'
				component={ConfigurationsHistory}
			/>
			<Route exact={true} path='/onboarding/country' component={SelectCountry} />
			<Route exact={true} path='/onboarding/doctor' component={DoctorOnboarding} />
			<Route exact={true} path='/onboarding/patient' component={PatientOnboarding} />
			<Route exact={true} path='/onboarding/select-company' component={SelectPatientCompany} />
			<Route exact={true} path='/onboarding/activation' component={UserActivation} />
			<Route exact={true} path='/validate/company' component={CompleteCompany} />
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/call/:callType/:patientId/:conferenceName/:incoming'
				component={Call}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
				path='/call'
				component={ConferenceCall}
				render={ConferenceRouteRender}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/patient-feed'
				component={ConferenceCall}
				render={ConferenceRouteRender}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
				path='/start-call/:objectId/:objectType/:name/:type/:conferenceId'
				component={CallRedirect}
			/>
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/check-in-dc' component={DigitalClinicianCheckIn} />
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/vital-signs-monitoring' component={VitalSignsMonitoring} />
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/vital-signs-monitoring'
				component={VitalSignsMonitoring}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/logistics' component={Logistics} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER]}
				path='/teams'
				component={Teams}
			/>
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/my-hello-devices/:id' component={MyHelloDevices} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/my-hello-devices' component={MyHelloDevices} />
			<PrivateRoute roles={[UserRoles.VISITOR]} path='/devices' component={VisitorWrapper} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/signs-symptoms' component={SignsSymptoms} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/available-doctors' component={AvailableDoctorsWrapper} />
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/front-desk-visits' component={DigitalClinicianVisitWrapper} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/complete-registration' component={CompleteRegistration} />
			<PrivateRoute path='/account-settings' component={AccountSettingsWrapper} />
			<PrivateRoute path='/coming-soon' component={ComingSoon} />
			<PrivateRoute
				type={RouteTypes.PRIMARY_CARE}
				roles={[UserRoles.DOCTOR]}
				path='/waiting-room-inperson/:patientId'
				component={InPersonVisitWrapper}
			/>
			<PrivateRoute
				type={RouteTypes.PRIMARY_CARE}
				roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
				path='/waiting-room-inperson'
				component={WaitingRoomHome}
			/>
			<PrivateRoute
				type={RouteTypes.PRIMARY_CARE}
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/call/:callType/:patientId/:conferenceName/:incoming'
				component={Call}
			/>
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/check-in-patient' component={CheckInHome} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/waiting-results' component={LabWaitingResultWrapper} />
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.NURSE]}
				path='/rpm-patient-onboarding'
				component={RpmOnboardingPatientWrapper}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/patient-monitoring' component={PatientMonitoringWrapper} />
			<PrivateRoute roles={[UserRoles.ADMIN]} path='/add-edit-rpm' component={AddEditRpmProgram} />
			<PrivateRoute roles={[UserRoles.SUPER_USER]} path='/queue-management' component={QueueManagementWrapper} />
			<PrivateRoute roles={[UserRoles.VISITOR]} path='/dependants' component={FamilyMemberPatientWrapper} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]}
				path='/care-notifications'
				component={CareNotificationsWrapper}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.NURSE]}
				path='/care-events-dashboard'
				component={CareEventsDashboardWrapper}
			/>
			<PrivateRoute path='*' component={Home} />
		</Switch>
	</Suspense>
);

export default DefaultRoutes;
