import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { participant as CallsParticipant } from '@solaborate/calls';
import { Modal } from 'calls/components/index.js';
import { useConference, useConferenceConfigurations } from 'calls/hooks/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import translate from 'i18n-translations/translate.jsx';
import { getRoomMembers } from 'api/organization.js';
import Alert from 'components/Alert.jsx';
import { getLowestLevelInterpreterConfiguration } from 'api/translationServices.js';
import classNames from 'classnames';
import CustomButton from 'components/Button.jsx';
import { findSectorById, isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';
import {
	InviteFamilyAndFriends,
	SendInvitesView,
	AddPeopleView,
	InviteGloboView,
	InviteLanguageLineView,
	InviteAmnView,
	InviteVoyceView,
	InviteCcadView,
} from 'calls/views/index.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isRightToLeft: boolean, $isDarkMode: boolean }, never>}
 */
const StyledInviteParticipants = styled.div`
	section {
		background: ${LightTheme.colors.grayZero};
		header {
			border-bottom: 1px solid ${LightTheme.colors.grayOne};
			h1 {
				color: ${LightTheme.colors.grayFive};
			}
			button {
				span {
					color: ${LightTheme.colors.grayFive};
				}
			}
		}
	}

	nav {
		display: flex;
		margin-bottom: ${LightTheme.spacing[5]}px;

		> button {
			flex: 1;
			background: none;
			border-radius: 0;
			text-transform: initial;
			color: ${LightTheme.colors.grayFive};

			padding: ${LightTheme.spacing[2]}px;
			border-bottom: 2px solid ${LightTheme.colors.grayTwo};
		}

		[data-active='true'] {
			border-bottom: 2px solid ${LightTheme.colors.blueOne};
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		li {
			background: ${LightTheme.colors.blueOne};
			font-size: 12px;
			padding: 4px;
			border-radius: ${LightTheme.borderRadius.base}px;
			color: ${LightTheme.colors.grayZero};
			margin-bottom: ${LightTheme.spacing[3]}px;
			margin-right: ${LightTheme.spacing[1]}px;
			display: flex;
			align-items: center;
			white-space: nowrap;

			button {
				all: revert;
				background: none;
				border: none;
				margin-left: ${LightTheme.spacing[1]}px;
				padding: 0;
				display: flex;
				cursor: pointer;

				span {
					color: ${LightTheme.colors.grayZero};
					font-size: 14px;
				}
			}
		}
	}

	main > div {
		border-bottom: 1px solid ${LightTheme.colors.grayOne};
		padding-bottom: ${LightTheme.spacing[5]}px;
		color: ${LightTheme.colors.grayFive};
		> div {
			display: flex;
			align-items: flex-start;
			width: 100%;

			input {
				flex: 1;
				width: 100%;
			}

			button {
				border: 1px solid ${LightTheme.colors.grayTwo};
				margin-left: ${props => (props.$isRightToLeft ? '0' : '8px')};
				margin-right: ${props => (props.$isRightToLeft ? '8px' : '0')};
			}
		}

		> button {
			padding: 0;
			span {
				margin-right: ${LightTheme.spacing[1]}px;
				color: ${LightTheme.colors.blueOne};
			}
		}
	}

	label {
		color: ${LightTheme.colors.graySeven};
	}

	input {
		background: ${LightTheme.colors.grayZero};
		color: ${LightTheme.colors.grayFive};
	}

	label ~ section {
		display: flex;
		width: 100%;
	}

	small > small {
		color: ${LightTheme.colors.redOne};
	}

	select {
		appearance: none;
		padding: ${LightTheme.spacing[2]}px;
		margin-bottom: ${LightTheme.spacing[3]}px;
		color: ${LightTheme.colors.grayFive};
		border: none;
		font-size: 14px;
		border-radius: ${LightTheme.borderRadius.base}px;
		width: 160px;
		margin-right: ${LightTheme.spacing[2]}px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23545454'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: ${props => (props.$isRightToLeft ? 'left 10px center;' : 'right 10px center;')};
		background-size: 20px;
		border: 1px solid transparent;
		background-color: ${LightTheme.colors.grayZero};
		box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 8%);

		+ input {
			width: 100%;
		}

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
			background-image: none;
		}
	}

	.translation-services select {
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: var(--spacing-xl);
	}

	${props =>
		props.$isDarkMode &&
		css`
			section {
				background: ${DarkTheme.colors.grayThree};
				header {
					border-bottom: 1px solid ${DarkTheme.colors.grayFour};
					h1 {
						color: ${DarkTheme.colors.grayFive};
					}
					button {
						span {
							color: ${DarkTheme.colors.grayFive};
						}
					}
				}
			}

			nav {
				> button {
					color: ${DarkTheme.colors.grayFive};
					border-bottom: 2px solid ${DarkTheme.colors.grayFour};
				}
			}

			ul {
				li {
					color: ${DarkTheme.colors.grayOne};

					button {
						span {
							color: ${DarkTheme.colors.grayFive};
						}
					}
				}
			}

			main > div {
				border-bottom: 1px solid ${DarkTheme.colors.grayFour};
				color: ${DarkTheme.colors.grayFive};
				> div {
					button {
						border: 1px solid ${DarkTheme.colors.grayFour};
					}
				}
			}

			label {
				color: ${DarkTheme.colors.grayFive};
			}

			input {
				background: ${DarkTheme.colors.grayFour};
				color: ${DarkTheme.colors.grayFive};
			}

			select {
				color: ${DarkTheme.colors.grayFive};
				background-color: ${DarkTheme.colors.grayFour};
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23cfd3da' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
			}

			.translation-services {
				.custom-select__menu {
					background-color: var(--dark-theme-gray-3);
					color: var(--dark-theme-gray-1);
				}
				.custom-select__option--is-focused {
					background-color: var(--dark-theme-gray-2);
				}
				.custom-select__single-value {
					color: var(--dark-theme-gray-1);
				}
			}
		`}
`;

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean }, never>}
 */

const tabNames = {
	FAMILY_FRIENDS: 1,
	ADD_PEOPLE: 2,
	TRANSLATION_SERVICES: 3,
	SENT_INVITES: 4,
};

/**
 * @param {object} props
 * @param {(message) => void} props.onDismiss
 * @param {string} [props.roomId]
 * @param {string} props.position
 * @param {object} props.configurations
 */
const InviteParticipantsView = ({ onDismiss, roomId, configurations = {}, position }) => {
	const intl = useIntl();
	const conference = useConference();
	const [error, setError] = useState(null);
	const healthSystems = useSelector(state => state.healthSystems);
	const [roomMembers, setRoomMembers] = useState([]);
	const [tab, setTab] = useState(tabNames.ADD_PEOPLE);
	const userSession = useSelector(state => state.user.userSession);
	const conferenceConfigs = useConferenceConfigurations();
	const [interpreterConfiguration, setInterpreterConfiguration] = useState(null);
	const [hospitalTimezone, setHospitalTimezone] = useState(null);

	useEffect(() => {
		if (roomId) {
			const fetchRoomMembers = async () => {
				const room = await getRoomMembers(userSession.healthSystem.id, roomId);
				setRoomMembers(room.roomMembers);
			};
			fetchRoomMembers();
		}
	}, [roomId, userSession.healthSystem.id]);

	useEffect(() => {
		const fetchTranslationProviders = async () => {
			const location = getTreeHierarchyParams();
			if (!location) {
				return;
			}
			const interpreterResponse = await getLowestLevelInterpreterConfiguration(location);
			if (interpreterResponse.error) {
				setError(interpreterResponse.error.message);
				return;
			}
			if (interpreterResponse.result) {
				setInterpreterConfiguration(interpreterResponse.result);
			}
			const timezone = getHospitalTimezone(location.hospitalId);
			if (timezone) {
				setHospitalTimezone(timezone);
			}
		};
		fetchTranslationProviders();
	}, [userSession.healthSystem.id]);

	const showOtherPeopleTab = () =>
		configurations.isMeetingURLOn ||
		configurations.isInviteViaEmailOn ||
		configurations.isInviteViaSmsOn ||
		configurations.isDialInOn ||
		configurations.isDialOutOn;

	const showSentInvitesTab = () => conferenceConfigs.invitedParticipants?.links?.length > 0 && configurations.isCancelInviteOn;

	const showTranslationServicesTab = () => interpreterConfiguration && configurations.isTranslationServicesOn;

	const showFamilyMembersTab = () => roomId && roomMembers?.length > 0;

	useEffect(() => {
		if (showOtherPeopleTab()) {
			setTab(tabNames.ADD_PEOPLE);
		} else if (showSentInvitesTab()) {
			setTab(tabNames.SENT_INVITES);
		} else if (showTranslationServicesTab()) {
			setTab(tabNames.TRANSLATION_SERVICES);
		} else if (showFamilyMembersTab()) {
			setTab(tabNames.FAMILY_FRIENDS);
		}
	}, [roomMembers, roomId]);

	const getTreeHierarchyParams = () => {
		const sector = findSectorById(healthSystems.treeData.tree, roomId);
		if (!sector) {
			return null;
		}
		const { hospitalId, departmentId, floorId } = sector;
		return { healthSystemId: userSession.healthSystem.id, hospitalId, departmentId, floorId, roomId };
	};

	const getHospitalTimezone = hospitalId => {
		const hospital = findSectorById(healthSystems.treeData.tree, hospitalId);
		return hospital?.timezone?.timezone || null;
	};

	const getInterpretationConfig = () => {
		if (!interpreterConfiguration) {
			return null;
		}
		if (isJSON(interpreterConfiguration.configJson)) {
			return JSON.parse(interpreterConfiguration.configJson);
		}
		return null;
	};

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			const isParticipantConnecting = participant && participant.state instanceof CallsParticipant.StateConnecting;
			if (isParticipantConnecting) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	return (
		<StyledInviteParticipants
			id='stress-test-add-people-modal'
			className={classNames(position ? 'invite-participants-view' : '')}
			$isRightToLeft={conferenceConfigs.isRightToLeft}
			$isDarkMode={conferenceConfigs.isDarkMode}>
			<Modal position={position} onDismiss={onDismiss} title={intl.formatMessage({ id: 'multipartyCalling' })}>
				<nav>
					{showOtherPeopleTab() && (
						<CustomButton
							marginRight={0}
							className='flex-justify-center'
							active={tab === tabNames.ADD_PEOPLE}
							onClick={() => setTab(tabNames.ADD_PEOPLE)}
							text={translate('addPeople')}
						/>
					)}
					{showSentInvitesTab() && (
						<CustomButton
							marginRight={0}
							className='flex-justify-center'
							active={tab === tabNames.SENT_INVITES}
							onClick={() => setTab(tabNames.SENT_INVITES)}
							text={translate('sentInvites')}
						/>
					)}
					{showTranslationServicesTab() && (
						<CustomButton
							marginRight={0}
							className='flex-justify-center'
							active={tab === tabNames.TRANSLATION_SERVICES}
							onClick={() => setTab(tabNames.TRANSLATION_SERVICES)}
							text={translate('requestInterpreterServices')}
						/>
					)}
					{showFamilyMembersTab() && (
						<CustomButton
							marginRight={0}
							className='flex-justify-center'
							active={tab === tabNames.FAMILY_FRIENDS}
							onClick={() => setTab(tabNames.FAMILY_FRIENDS)}
							text={translate('familyFriends')}
						/>
					)}
				</nav>
				{interpreterConfiguration && tab === tabNames.TRANSLATION_SERVICES && (
					<>
						{interpreterConfiguration.configurationTypeId === configurationTypeIds.GLOBO.id && (
							<InviteGloboView roomId={roomId} setError={setError} interpretationConfig={getInterpretationConfig()} />
						)}
						{interpreterConfiguration.configurationTypeId === configurationTypeIds.AMN.id && (
							<InviteAmnView
								interpretationConfig={getInterpretationConfig()}
								setError={setError}
								onDismiss={onDismiss}
								removeParticipantOnNoAnswer={removeParticipantOnNoAnswer}
							/>
						)}
						{interpreterConfiguration.configurationTypeId === configurationTypeIds.LANGUAGE_LINE.id && (
							<InviteLanguageLineView
								interpretationConfig={getInterpretationConfig()}
								setError={setError}
								onDismiss={onDismiss}
								removeParticipantOnNoAnswer={removeParticipantOnNoAnswer}
							/>
						)}
						{interpreterConfiguration.configurationTypeId === configurationTypeIds.VOYCE.id && (
							<InviteVoyceView setError={setError} interpretationConfig={getInterpretationConfig()} roomId={roomId} />
						)}
						{interpreterConfiguration.configurationTypeId === configurationTypeIds.CCAD.id && (
							<InviteCcadView
								interpretationConfig={getInterpretationConfig()}
								hospitalTimezone={hospitalTimezone}
								setError={setError}
								onDismiss={onDismiss}
								removeParticipantOnNoAnswer={removeParticipantOnNoAnswer}
							/>
						)}
					</>
				)}
				{tab === tabNames.FAMILY_FRIENDS && <InviteFamilyAndFriends roomId={roomId} onDismiss={onDismiss} />}
				{tab === tabNames.SENT_INVITES && <SendInvitesView setTab={setTab} />}
				{tab === tabNames.ADD_PEOPLE && (
					<AddPeopleView
						configurations={configurations}
						setError={setError}
						onDismiss={onDismiss}
						removeParticipantOnNoAnswer={removeParticipantOnNoAnswer}
					/>
				)}
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</Modal>
		</StyledInviteParticipants>
	);
};

export default InviteParticipantsView;
