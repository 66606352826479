import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import Checkbox from 'components/Checkbox.jsx';
import Grid from 'components/Grid.jsx';
import { Button } from 'components/index.js';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import { SortOrder } from 'constants/enums.js';
import { Collapse, Expand, Sort, SortActive } from 'icons/General/index.js';
import { sortingComparator } from 'infrastructure/helpers/commonHelpers.js';
import { debounce } from 'lodash';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

const TableWrapper = styled.div`
	height: ${props => props.$height};
`;

const TableHeader = styled.th`
	font-weight: normal;
	padding: var(--spacing-m) var(--spacing-l);
	user-select: none;
	color: var(--gray-3);
	> span {
		display: inline-flex;
		align-items: center;
		gap: var(--spacing-m);
		color: inherit;
		.multi-sort-order {
			color: var(--blue-2);
			display: inline-flex;
			font-size: 9px;
		}
	}
	${props =>
		props.$width &&
		css`
			white-space: normal !important;
			min-width: ${props.$width};
			max-width: ${props.$width};
			width: ${props.$width};
		`}
	&.sticky-column {
		position: sticky;
		z-index: 1;
		background: var(--gray-0);
		${({ $isLastStickyColumn }) =>
			$isLastStickyColumn &&
			css`
				border-right: 1px solid var(--gray-2);
			`}
		${({ $width = '200px', $index }) => css`
			left: calc(${$width} * ${$index});
			min-width: ${$width};
			max-width: ${$width};
		`}
		white-space: normal !important;
		word-break: break-word;
	}
`;

const TableCell = styled.td`
	&.sticky-column {
		position: sticky;
		z-index: 1;
		background: var(--gray-0);
		${({ $isLastStickyColumn }) =>
			$isLastStickyColumn &&
			css`
				border-right: 1px solid var(--gray-2);
			`}
		${({ $width = '200px', $index }) => css`
			left: calc(${$width} * ${$index});
			min-width: ${$width};
			max-width: ${$width};
		`}
		white-space: normal !important;
		word-break: break-word;
	}
`;

const TableRow = ({ row, ...props }) => {
	const expandRow = () =>
		props.setExpandedRows(prevState => ({
			...prevState,
			[row.id]: !prevState[row.id],
		}));

	return (
		<tr
			className={classNames(row.className, {
				'row-expanded': props.isExpanded,
				'row-nested': props.isChild,
				'row-nested-last': props.isChild && props.isLastChild,
			})}
			data-test-id='table-row'>
			{props.isEditable && (
				<td className='padding-s'>
					<Checkbox />
				</td>
			)}
			{props.isNested && (
				<td className='padding-s'>
					{!props.keepRowsExpanded && row.children?.length > 0 && (
						<Button
							svgIcon={props.isExpanded ? <Expand /> : <Collapse transform='rotate(90)' />}
							background='transparent'
							color={LightTheme.colors.grayTen}
							border='none'
							onClick={expandRow}
						/>
					)}
				</td>
			)}
			{props.children}
		</tr>
	);
};

const CustomTable = ({
	headers = [],
	stickyHeader = false,
	horizontalScroll = false,
	height = 'auto',
	className = '',
	children = null,
	headerClass = 'padding-l',
	isEditable = false,
	rows = [],
	isLoading = false,
	tableEmptyText = null,
	isNested = false,
	keepRowsExpanded = false,
	setPagination = null,
	sortable = false,
	sortingFunction = null,
	multiSort = false,
	defaultSort = null,
}) => {
	const [expandedRows, setExpandedRows] = useState({});
	const [allExpanded, setAllExpanded] = useState(false);
	const [sortedColumns, setSortedColumns] = useState(multiSort ? defaultSort ?? [] : defaultSort);
	const [sortedRows, setSortedRows] = useState([]);
	const loadMoreRef = useRef(null);
	const intl = useIntl();

	const expandAllRows = () => {
		setExpandedRows(
			rows.reduce((acc, row) => (row?.children?.length > 0 ? { ...acc, [row.id]: !allExpanded } : acc), expandedRows)
		);
		setAllExpanded(prevState => !prevState);
	};

	useEffect(() => {
		if (!sortingFunction && (sortable || headers.some(header => header.sortable))) {
			applyTableSorting(sortedColumns);
		}
		if (isNested && !setPagination) {
			setExpandedRows({});
			setAllExpanded(false);
		}
	}, [rows]);

	useEffect(() => {
		if (!isNested) {
			return;
		}
		const expandedCount = Object.values(expandedRows).filter(Boolean).length;
		const expandableCount = rows.filter(row => row?.children?.length > 0).length;
		setAllExpanded(expandedCount !== 0 && expandedCount === expandableCount);
	}, [expandedRows]);

	const isLastStickyColumn = index => {
		const stickyHeaders = headers.filter(header => header.sticky);
		return stickyHeaders.length === index + 1;
	};

	const getRowDataByIndex = (row, cellIndex) => {
		const rowKeys = Object.keys(row).filter(key => !['id', 'className', 'children', 'cellClassName'].includes(key));
		return row?.[rowKeys[cellIndex]];
	};

	const shouldBreakWord = content => typeof content === 'string' && content?.split(' ').some(word => word.length > 45);

	const handleLoadNextPage = useCallback(
		debounce(loadNext => {
			if (setPagination && !isLoading && loadNext) {
				setPagination(prevState =>
					prevState.totalCount > rows.length ? { ...prevState, pageIndex: prevState.pageIndex + 1 } : prevState
				);
			}
		}, 100),
		[isLoading, rows.length, setPagination]
	);

	useEffect(() => {
		if (!setPagination) {
			return undefined;
		}
		const loadMoreContainer = loadMoreRef.current;
		const handleIntersection = entries => {
			const [entry] = entries;
			handleLoadNextPage(entry.isIntersecting);
		};

		const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
		if (loadMoreRef.current) {
			observer.observe(loadMoreContainer);
		}
		return () => {
			if (setPagination && loadMoreContainer) {
				observer.unobserve(loadMoreContainer);
			}
		};
	}, [handleLoadNextPage, loadMoreRef, setPagination]);

	const applyTableSorting = sortedColumns => {
		if (sortingFunction) {
			sortingFunction(sortedColumns);
			return;
		}
		let sortedArray = [...rows];
		if (sortedArray.length === 0) {
			return;
		}
		if (!multiSort && sortedColumns !== null) {
			const { columnId, order } = sortedColumns;
			sortedArray.sort((a, b) => sortingComparator(a[columnId], b[columnId], order));
		} else if (Array.isArray(sortedColumns)) {
			sortedArray.sort((a, b) => {
				let compareResult = 0;
				sortedColumns.forEach(
					({ columnId, order }) => (compareResult = compareResult || sortingComparator(a[columnId], b[columnId], order))
				);
				return compareResult;
			});
		}
		setSortedRows(sortedArray);
	};

	const handleSortChange = columnId => {
		let newSortedColumns;
		if (!multiSort) {
			const sortOrder =
				sortedColumns?.columnId === columnId && sortedColumns.order === SortOrder.ASCENDING
					? SortOrder.DESCENDING
					: SortOrder.ASCENDING;
			newSortedColumns = { columnId, order: sortOrder };
		} else if (Array.isArray(sortedColumns)) {
			const sortIndex = sortedColumns.findIndex(sorted => sorted.columnId === columnId);
			newSortedColumns = sortedColumns
				.map((item, index) =>
					index === sortIndex
						? {
								...item,
								order: (item.order + 1) % 3,
						  }
						: item
				)
				.filter(sorted => sorted.order !== SortOrder.UNSET);
			if (sortIndex === -1) {
				newSortedColumns.push({ columnId, order: SortOrder.ASCENDING });
			}
		}
		setSortedColumns(newSortedColumns);
		applyTableSorting(newSortedColumns);
	};

	const getSortOrder = columnId => {
		if (!multiSort) {
			return sortedColumns?.columnId === columnId ? sortedColumns.order : SortOrder.UNSET;
		} else if (Array.isArray(sortedColumns)) {
			return sortedColumns.find(sorted => sorted.columnId === columnId)?.order ?? SortOrder.UNSET;
		}
		return SortOrder.UNSET;
	};

	const getSortOrderTooltip = order => {
		switch (order) {
			case SortOrder.UNSET:
				return intl.formatMessage({ id: 'sortAscending' });
			case SortOrder.ASCENDING:
				return intl.formatMessage({ id: 'sortDescending' });
			case SortOrder.DESCENDING:
				return intl.formatMessage({ id: multiSort ? 'removeSorting' : 'sortAscending' });
			default:
				return intl.formatMessage({ id: 'removeSorting' });
		}
	};

	return (
		<TableWrapper
			className={classNames('custom-table-wrapper', {
				'sticky-header': stickyHeader,
				'horizontal-scroll': horizontalScroll,
			})}
			$height={height}>
			{children && <header className={headerClass}>{children}</header>}
			<main>
				<table className={classNames('custom-table', className)}>
					<thead>
						<tr>
							{isEditable && (
								<TableHeader>
									<Checkbox />
								</TableHeader>
							)}
							{isNested && (
								<TableHeader id='expand-all'>
									{!keepRowsExpanded && rows.some(row => row.children.length > 0) && (
										<Button
											svgIcon={allExpanded ? <Expand /> : <Collapse transform='rotate(90)' />}
											background='transparent'
											color={LightTheme.colors.grayTen}
											border='none'
											onClick={expandAllRows}
										/>
									)}
								</TableHeader>
							)}
							{headers.map((header, headerIndex) => (
								<TableHeader
									key={header.id || `header-${headerIndex}`}
									className={classNames(header.thClass || '', { 'sticky-column': header?.sticky })}
									$width={header.columnWidth}
									$index={headerIndex}
									$isLastStickyColumn={isLastStickyColumn(headerIndex)}>
									<span
										className={classNames({ 'cursor-pointer': header.sortable ?? sortable })}
										data-tooltip={header.sortable ?? sortable ? getSortOrderTooltip(getSortOrder(header.id)) : null}
										data-delay='700'
										data-position='top'
										onClick={() => (header.sortable ?? sortable) && handleSortChange(header.id)}>
										{header.title}
										{(header.sortable ?? sortable) && (
											<>
												{getSortOrder(header.id) === SortOrder.UNSET && <Sort width={16} height={16} />}
												{getSortOrder(header.id) !== SortOrder.UNSET && (
													<span className='multi-sort-order'>
														<SortActive
															className={classNames({
																'rotate-180': getSortOrder(header.id) === SortOrder.ASCENDING,
															})}
															color={LightTheme.colors.blueSeven}
															width={16}
															height={16}
														/>
														{multiSort && sortedColumns.findIndex(sorted => sorted.columnId === header.id) + 1}
													</span>
												)}
											</>
										)}
									</span>
								</TableHeader>
							))}
						</tr>
					</thead>
					<tbody>
						{((sortable || headers.some(header => header.sortable)) && !sortingFunction ? sortedRows : rows).map(
							(row, rowIndex) => (
								<Fragment key={row?.id || `row-${rowIndex}`}>
									<TableRow
										key={row?.id || `row-${rowIndex}`}
										row={row}
										isEditable={isEditable}
										isNested={isNested}
										keepRowsExpanded={keepRowsExpanded}
										isExpanded={expandedRows?.[row?.id] || (keepRowsExpanded && row?.children?.length > 0)}
										setExpandedRows={setExpandedRows}>
										{headers.map((header, columnIndex) => (
											<TableCell
												key={header.id || `cell-${columnIndex}`}
												className={classNames(
													header?.columnClass || '',
													{
														'sticky-column': header?.sticky,
														'word-break-all': shouldBreakWord(row?.[header.id] ?? getRowDataByIndex(row, columnIndex)),
													},
													row.cellClassName ? row.cellClassName[columnIndex] : ''
												)}
												$width={header?.columnWidth}
												$index={columnIndex}
												$isLastStickyColumn={isLastStickyColumn(columnIndex)}>
												{row?.[header.id] ?? getRowDataByIndex(row, columnIndex)}
											</TableCell>
										))}
									</TableRow>
									{isNested &&
										row?.children &&
										(expandedRows?.[row.id] || keepRowsExpanded) &&
										row.children.map((child, childIndex) => (
											<TableRow
												key={child?.id || `row-${rowIndex}-${childIndex}`}
												row={child}
												isEditable={isEditable}
												isNested={isNested}
												isChild={true}
												isLastChild={childIndex === row.children.length - 1}>
												{headers.map((header, columnIndex) => (
													<TableCell
														key={header.id || `cell-${columnIndex}`}
														className={classNames(header?.columnClass || '', {
															'sticky-column': header?.sticky,
															'word-break-all': shouldBreakWord(child?.[header.id] ?? getRowDataByIndex(child, columnIndex)),
														})}
														$width={header?.columnWidth}
														$index={columnIndex}
														$isLastStickyColumn={isLastStickyColumn(columnIndex)}>
														{child?.[header.id] ?? getRowDataByIndex(child, columnIndex)}
													</TableCell>
												))}
											</TableRow>
										))}
								</Fragment>
							)
						)}
						{!!isLoading && (
							<tr>
								<td colSpan={headers.length + [isEditable, isNested].filter(Boolean).length}>
									<Grid width='100%' horizAlign='center'>
										<SkeletonLoader rows={setPagination && rows.length !== 0 ? 3 : 10} padding='35px 20px' />
									</Grid>
								</td>
							</tr>
						)}
						{!isLoading && rows?.length === 0 && (
							<tr>
								<td colSpan={headers.length + [isEditable, isNested].filter(Boolean).length} className='empty-state-content'>
									{tableEmptyText || intl.formatMessage({ id: 'noData' })}
								</td>
							</tr>
						)}
					</tbody>
					{setPagination && <tfoot ref={loadMoreRef}></tfoot>}
				</table>
			</main>
		</TableWrapper>
	);
};

export default CustomTable;
