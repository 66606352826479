import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import 'font-awesome/css/font-awesome.min.css';
import 'css/index.scss'; 
import ErrorBoundary from 'components/Utils/ErrorBoundary.jsx';
import store from './store/configureStore.jsx';
import { unregister } from './registerServiceWorker.js';
import App from './App.jsx';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<BrowserRouter basename='/'>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</BrowserRouter>
	</Provider>
);
unregister();
