import { LocalTrackAdded, Mic, Cam } from '@solaborate/calls/webrtc';
import { enums } from '@solaborate/calls';
import html2canvas from 'html2canvas';
import { UserRoles, UserTypes } from 'calls/enums/index.js';
import { InterpreterCallTypeOptions } from 'constants/enums.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getStorage, isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import { loadTfLite, StreamBackgroundEffect } from 'calls/views/TrackWithBackground.jsx';
import { VirtualBackgroundTypes } from 'calls/views/VirtualBackgroundProvider.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const imageUrlToBase64 = url =>
	fetch(url)
		.then(response => response.blob())
		.then(
			blob =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				})
		);

const captureFrame = async element => {
	if (!element) {
		return;
	}
	const canvas = await html2canvas(element);
	const anchor = document.createElement('a');
	anchor.href = canvas.toDataURL('image/jpeg');
	anchor.download = `${+new Date()}.jpeg`;
	anchor.hidden = true;
	document.body.appendChild(anchor);
	anchor.click();
	anchor.remove();
};

/**
 * Method used to return requested track types from call type
 * @param {import('@solaborate/calls').types.CallType} callType
 */
const callTypeToTrackTypes = callType => {
	let localTrackTypes = [Mic, Cam];
	let remoteTrackTypes = [Mic, Cam];

	if (callType === enums.CallTypes.SECURITYCAM) {
		localTrackTypes = [];
	} else if (callType === enums.CallTypes.MONITORING) {
		localTrackTypes = [];
		remoteTrackTypes = [Cam];
	} else if (callType === enums.CallTypes.AUDIO || callType === enums.CallTypes.FIRST_RESPONDER) {
		localTrackTypes = [Mic];
		remoteTrackTypes = [Mic];
	}

	return {
		localTrackTypes,
		remoteTrackTypes,
	};
};

const getUserRoleId = () => {
	const userRoleId =
		sessionStorage.getItem('userRole') === UserRoles.GUEST
			? sessionStorage.getItem('userRoleId')
			: getStorage().getItem('userRoleId');

	if (!userRoleId) {
		return null;
	}

	return +userRoleId;
};

const isMedicalInfoModalsOpen = medicalDataControls => {
	const {
		isDiagnosesVisible,
		isProceduresVisible,
		isNotesVisible,
		isPrescriptionsVisible,
		isPhysicalExercisesVisible,
		isCareEventsFormVisible,
	} = medicalDataControls;

	return (
		isDiagnosesVisible ||
		isProceduresVisible ||
		isNotesVisible ||
		isPrescriptionsVisible ||
		isPhysicalExercisesVisible ||
		isCareEventsFormVisible
	);
};

const attachSinkId = async (element, sinkId) => {
	if (typeof element?.sinkId !== 'undefined') {
		try {
			return await element.setSinkId(sinkId);
		} catch (error) {
			return { error };
		}
	} else {
		return { error: 'Browser does not support output device selection.' };
	}
};

const getConferenceEndedTimeout = () => {
	const threeSeconds = 3000;
	const tenSeconds = 10000;
	if (getUserRole() === UserRoles.VISITOR) {
		return tenSeconds;
	}
	return threeSeconds;
};

const getSavedStreamSettings = () => {
	const streamSettings = getStorage().getItem('streamSettings');
	return streamSettings ? JSON.parse(streamSettings) : {};
};

const changeLocalParticipantBackground = async ({ localParticipant, streamEffect, selectedBackground, backgroundUrl = '' }) => {
	if (isMobileOrTablet()) {
		return;
	}
	let streamBackgroundEffect = streamEffect;
	const videoTrack = localParticipant.localTrackController.tracks[Cam];
	let modelOptions = {
		height: 144,
		width: 256,
		virtualBackground: {
			backgroundEffectEnabled: false,
			backgroundType: 'none',
		},
	};

	if (!streamBackgroundEffect) {
		const videoTrack = localParticipant.localTrackController.tracks[Cam];
		if (videoTrack && videoTrack instanceof StreamBackgroundEffect) {
			modelOptions = { ...videoTrack.options };
		}
		const newTracks = await localParticipant.localTrackController.factory.createTracks([Cam]);
		const tflite = await loadTfLite();
		streamBackgroundEffect = new StreamBackgroundEffect(newTracks[0], tflite, modelOptions);
		if (selectedBackground === VirtualBackgroundTypes.BLUR) {
			streamBackgroundEffect.blurBackground(20);
		}
		if (![VirtualBackgroundTypes.BLUR, VirtualBackgroundTypes.NONE].includes(selectedBackground)) {
			streamBackgroundEffect.changeBackground(backgroundUrl);
		}
	}

	let newVideoTrack = streamBackgroundEffect;
	if (!streamBackgroundEffect.options.virtualBackground.backgroundEffectEnabled) {
		newVideoTrack = streamBackgroundEffect.originalTrack;
		streamBackgroundEffect.stopEffect();
	}
	localParticipant.localTrackController.tracks[Cam] = newVideoTrack;
	localParticipant.localTrackController.fire(new LocalTrackAdded(newVideoTrack));
	await localParticipant.localTrackController.add([]);
	if (videoTrack instanceof StreamBackgroundEffect) {
		videoTrack.stop();
	}
};

const findRemoteHelloParticipant = participants => {
	return [...participants.values()].find(item => item instanceof RemoteHelloParticipant);
};

const findPatientRoleParticipant = participants => {
	return [...participants.values()].find(item => item?.role === UserTypes.PATIENT);
};

const getCallsButtonBackground = darkMode => {
	return darkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayZero;
};

const getCallsControlsButtonBackground = (darkMode, modalCallControlsButton = false) => {
	if (modalCallControlsButton) {
		return darkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayNinetyEight;
	}
	return darkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayNinetyEight;
};

const getInterpreterCallTypeOptions = intl =>
	InterpreterCallTypeOptions.map((type) => ({
		value: type.value,
		label: intl.formatMessage({ id: type.label }),
	}));

export {
	captureFrame,
	callTypeToTrackTypes,
	getUserRoleId,
	imageUrlToBase64,
	getConferenceEndedTimeout,
	attachSinkId,
	getSavedStreamSettings,
	isMedicalInfoModalsOpen,
	changeLocalParticipantBackground,
	getCallsButtonBackground,
	getCallsControlsButtonBackground,
	findRemoteHelloParticipant,
	findPatientRoleParticipant,
	getInterpreterCallTypeOptions,
};
