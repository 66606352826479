import classNames from 'classnames';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALES } from 'i18n-translations/locales.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import { ConversationType, ObjectType, UserRoles } from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getConversationByType } from 'api/messenger.js';
import { CompanySettings } from 'constants/configurationEnums.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { getConfigurationValue, lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
import { Loader } from 'calls/components/index.js';
const Conversation = lazyWithRetry(() => import('views/Chat/Conversation.jsx'));

const SupportChat = () => {
	const dispatch = useDispatch();
	const isMonitoring = () => window.location.pathname.startsWith('/monitoring');
	const isInCall = () => window.location.pathname.startsWith('/call');
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const locale = useSelector(state => state.language.locale);
	const [isHelpDeskOpen, setIsHelpDeskOpen] = useState(false);
	const [conversationId, setConversationId] = useState(null);

	const showHelpDesk = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.SUPPORT_CHAT]) &&
		APP_CONFIG.helpDeskId &&
		!['/patient-feed', '/help', '/call'].some(item => item.startsWith(window.location.pathname)) &&
		[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(getUserRole());

	useEffect(() => {
		if (isInCall()) {
			return;
		}
		if (![UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(getUserRole())) {
			return;
		}
		if (conversationId) {
			return;
		}
		const loadConversation = async () => {
			if (!APP_CONFIG.helpDeskId) {
				return;
			}
			const response = await getConversationByType(ConversationType.SUPPORT, APP_CONFIG.helpDeskId, ObjectType.COMPANY);
			if (response.error) {
				return;
			}
			setConversationId(response.conversation.id);
		};
		loadConversation();
	}, [conversationId]);

	const getClassForHelpDesk = () => {
		if (isMonitoring() && locale === LOCALES.ARABIC) {
			return '';
		}
		if (isMonitoring() && locale !== LOCALES.ARABIC) {
			return 'left';
		}
		return locale === LOCALES.ARABIC ? 'left' : '';
	};

	return (
		<>
			{showHelpDesk() && (
				<span
					className={classNames('help-conversation', getClassForHelpDesk())}
					onClick={() => {
						if (isMonitoring() && !isHelpDeskOpen) {
							dispatch(healthSystemsActionCreators.toggleLeftNavigationWithValue(isHelpDeskOpen));
						}
						setIsHelpDeskOpen(prevState => !prevState);
					}}>
					<span className='material-icons'>{isHelpDeskOpen ? 'close' : 'chat'}</span>
				</span>
			)}
			{conversationId && (
				<div className={classNames('help-conversation-wrapper', !isHelpDeskOpen ? 'display-none' : '', getClassForHelpDesk())}>
					<header>
						<img src={`${healthCareCdnUrl}healthcare-icon.svg`} alt='Hello care icon' />
						Hello Care Team
					</header>
					<Suspense fallback={<Loader />}>
						<Conversation
							conversationId={conversationId}
							isLeftNavigationExpanded={false}
							hideRightSide={true}
							isHelpDesk={true}
						/>
					</Suspense>
				</div>
			)}
		</>
	);
};

export default SupportChat;
