import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import EmptyState from 'components/EmptyState.jsx';
import { getManualAlertActivities, getPatientManualAlerts } from 'api/alerts.js';
import AlertHistoryModal from 'components/AlertHistoryModal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Pagination from 'components/Common/Pagination.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import ReportAsFalse from 'icons/Monitoring/ReportAsFalse.jsx';
import { manualAlertType } from 'constants/alerts.jsx';
import { TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { ManualAlertCategoryTypes } from 'constants/enums.js';
import { LOCALES } from 'i18n-translations/locales.js';
import Button from 'components/Button.jsx';

const CareEvents = ({ patientId }) => {
	const tabs = {
		MANUAL_ALERTS: 0,
		DETAILS: 1,
	};
	const [currentTab, setCurrentTab] = useState(tabs.MANUAL_ALERTS);
	const [manualAlerts, setManualAlerts] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [alertDetails, setAlertDetails] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const locale = useSelector(state => state.language.locale);
	const isArabic = locale === LOCALES.ARABIC;

	useEffect(() => {
		const fetchManualAlerts = async () => {
			const params = {
				patientId,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				category: ManualAlertCategoryTypes.FrontLineNurses,
				subCategory: ManualAlertCategoryTypes.SafetyCompanions,
			};
			if (!patientId) {
				return;
			}
			const response = await getPatientManualAlerts(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setManualAlerts(response.patientManualAlerts);
			}
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
		};
		setIsLoading(true);
		fetchManualAlerts();
	}, [patientId, pagination.pageIndex, pagination.pageSize]);

	const imageUrl = `${healthCareCdnUrl}vsm/`;

	const handleChangeTab = async (tab, id = null) => {
		if (id) {
			const response = await getManualAlertActivities(id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setAlertDetails(response.manualAlertActivities);
		}
		setCurrentTab(tab);
	};

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	return (
		<>
			<Tabs activeIndex={currentTab}>
				<TabPanels>
					<TabPanel>
						<div className='patient-summary-wrapper measurements-alerts-wrapper'>
							<h3>{translate('careEvents')}</h3>
							{manualAlerts.length === 0 && !isLoading && (
								<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
									<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
								</div>
							)}
							{manualAlerts.length > 0 && !isLoading && (
								<>
									<div className='patient-summary-table-wrapper'>
										{manualAlerts.map(item => (
											<div key={item.id} className='patient-request-list-item cursor-pointer'>
												<div className='alert-icon'>
													{manualAlertType(user.darkMode).find(el => el.type === item.manualAlertType.id)?.icon || (
														<ReportAsFalse color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
													)}
												</div>
												<div>
													<p>{intl.formatMessage({ id: item.manualAlertType.name })}</p>
													<p className={classNames('alert-time', isArabic ? 'left-position' : '')}>
														<img src={`${imageUrl}Time.svg`} alt='ico' />
														{formattedDate(item.dateCreated)}
													</p>
												</div>
												<div className='flex-1 flex flex-justify-center right-align-items'>
													<Button
														type='button'
														className={classNames('alert-view-details-btn', isArabic ? 'left-position' : '')}
														onClick={() => handleChangeTab(tabs.DETAILS, item.id)}
														imgIcon={`${imageUrl}View-Details.svg`}
														text={translate('viewDetails')}
													/>
												</div>
											</div>
										))}
									</div>
									<Pagination
										totalCount={pagination.totalCount}
										pageSize={pagination.pageSize}
										pageIndex={pagination.pageIndex}
										onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
									/>
								</>
							)}

							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					</TabPanel>
					{alertDetails && (
						<TabPanel>
							<div
								className={classNames(
									`view-request-history-wrapper patient-summary-wrapper ${!alertDetails.length ? 'center-data' : ''}`
								)}>
								<div className='flex flex-align-center left-margin-s'>
									<Button className='alert-details-btn' onClick={() => handleChangeTab(tabs.MANUAL_ALERTS)} icon='chevron_left' />
									<p className='bold'>{translate('alertDetails')}</p>
								</div>
								<AlertHistoryModal alertDetails={alertDetails} isManual={true} />
							</div>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
		</>
	);
};

export default CareEvents;
