import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { dialOutTranslator } from 'api/sipProvider.js';
import { useConference } from 'calls/hooks/index.js';
import { ButtonType, InterpreterCallTypes } from 'constants/enums.js';
import CustomSelect from 'components/Select.jsx';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';

const getCurrentTime = timeZone => moment.tz(timeZone).format('HH:mm:ss');

const isTimeInRange = (currentTime, from, to) =>
	from <= to ? currentTime >= from && currentTime < to : currentTime >= from || currentTime < to;

/**
 * @param {object} props
 * @param {object} props.interpretationConfig
 * @param {string} props.hospitalTimezone
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {(participantId: string, message: string) => void} props.removeParticipantOnNoAnswer
 */

const InviteCcadView = ({ interpretationConfig, hospitalTimezone, onDismiss, setError, removeParticipantOnNoAnswer }) => {
	const intl = useIntl();
	const conference = useConference();

	const [patientPreferredLanguage, setPatientPreferredLanguage] = useState(null);
	const [selectedProvider, setSelectedProvider] = useState(null);

	const providers = useMemo(() => {
		if (!interpretationConfig?.providers) {
			return [];
		}
		return Object.entries(interpretationConfig.providers).map(([key, value], index) => ({
			id: key,
			name: `${intl.formatMessage({ id: 'ext' })}. ${index + 1}`,
			extension: value.extension,
			time: value.time,
		}));
	}, [interpretationConfig]);

	const autoSelectProvider = () => {
		const currentTime = getCurrentTime(hospitalTimezone);
		const availableProvider = providers.find(provider => isTimeInRange(currentTime, provider.time.from, provider.time.to));
		return availableProvider || providers[0];
	};

	useEffect(() => {
		if (!interpretationConfig || !providers.length || selectedProvider) {
			return;
		}
		setSelectedProvider(autoSelectProvider());
	}, [interpretationConfig, providers, selectedProvider]);

	useEffect(() => {
		const patientInfo = conference.additionalData.find(item => item.key === 'patientInfo');
		if (patientInfo) {
			const { preferredLanguage } = patientInfo.value;
			setPatientPreferredLanguage(preferredLanguage);
		}
	}, []);

	const inviteDialOutTranslator = async () => {
		if (!selectedProvider) {
			return;
		}
		const isCallTypeAudioOnly = interpretationConfig?.callType === InterpreterCallTypes.AUDIO;
		const response = await dialOutTranslator(selectedProvider.extension, conference.conferenceId, isCallTypeAudioOnly);
		if (response.error) {
			setError(translate('serviceProvicerNoAnswer'));
			return;
		}
		removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
		onDismiss();
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					{patientPreferredLanguage && (
						<div className='bottom-20'>
							{translate('patientPreferredLanguage')}: {patientPreferredLanguage}
						</div>
					)}
					<div className='bottom-20 column-direction'>
						<p style={{ margin: 0, padding: 0 }}>{translate('ccadExt1')}</p>
						<p style={{ margin: 0, padding: 0 }}>{translate('ccadExt2')}</p>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={`${intl.formatMessage({ id: 'ext' })}.`}
							name='selectExt'
							items={providers}
							valueField='id'
							textField='name'
							placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'ext' })}.`}
							value={selectedProvider?.id || ''}
							onSelect={event => {
								setSelectedProvider(providers.find(provider => provider.id === event.target.value));
							}}
						/>
					</div>
					<div className='interpreter-desc'>
						{!selectedProvider && <p>{translate('noAvailableExt')}</p>}
						{selectedProvider && <p>{translate('ccadInviteDescription')}</p>}
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button type='submit' onClick={inviteDialOutTranslator} variant={ButtonType.SUBMIT} disabled={!selectedProvider}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteCcadView;
