import { Suspense } from 'react';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid } from 'components/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const Appointment = lazyWithRetry(() => import('views/Appointments.jsx'));

const AppointmentWrapper = () => (
	<Suspense
		fallback={
			<MainLayout>
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			</MainLayout>
		}>
		<Appointment />
	</Suspense>
);

export default AppointmentWrapper;
