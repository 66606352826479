import { Suspense } from 'react';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid } from 'components/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const CareEventsDashboard = lazyWithRetry(() => import('containers/CareEventsDashboard/CareEventsDashboard.jsx'));

const CareEventsDashboardWrapper = () => (
	<Suspense
		fallback={
			<MainLayout>
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			</MainLayout>
		}>
		<CareEventsDashboard />
	</Suspense>
);

export default CareEventsDashboardWrapper;
