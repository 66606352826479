import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid } from 'components/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const Monitoring = lazyWithRetry(() => import('views/Monitoring.jsx'));
const MonitoringMayo = lazyWithRetry(() => import('views/MonitoringMayo.jsx'));

const MonitoringWrapper = () => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);

	return (
		<Suspense
			fallback={
				<MainLayout isMonitoring={true}>
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				</MainLayout>
			}>
			{isNewExperience ? <MonitoringMayo /> : <Monitoring />}
		</Suspense>
	);
};

export default MonitoringWrapper;
