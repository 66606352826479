import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Pagination from 'components/Common/Pagination.jsx';
import EmptyState from 'components/EmptyState.jsx';
import { getAiAlertActivities, getPatientAiAlerts } from 'api/alerts.js';
import AlertHistoryModal from 'components/AlertHistoryModal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { aiAlertType } from 'constants/alerts.jsx';
import { TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { LOCALES } from 'i18n-translations/locales.js';
import Button from 'components/Button.jsx';
import PatientPosition from 'icons/Monitoring/PatientPosition.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const AiAlerts = props => {
	const tabs = {
		ARTIFICIAL_INTELLIGENCE: 0,
		DETAILS: 1,
	};
	const [currentTab, setCurrentTab] = useState(tabs.ARTIFICIAL_INTELLIGENCE);
	const [aiAlerts, setAiAlerts] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [alertDetails, setAlertDetails] = useState(null);
	const user = useSelector(state => state.user);
	const locale = useSelector(state => state.language.locale);
	const isArabic = locale === LOCALES.ARABIC;

	const intl = useIntl();

	useEffect(() => {
		setIsLoading(true);
		const getaiAlerts = async (pageSize, pageIndex) => {
			const params = {
				pageSize,
				pageIndex,
				userId: props.patientId,
			};
			const response = await getPatientAiAlerts(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setAiAlerts(response.aiAlerts);
			}
			setIsLoading(false);
			setPagination({ pageSize, pageIndex, totalCount: response.totalCount || 0 });
		};
		getaiAlerts(pagination.pageSize, pagination.pageIndex);
	}, [props.patientId, props.type, pagination.pageIndex, pagination.pageSize]);

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	const imageUrl = `${healthCareCdnUrl}vsm/`;

	const handleChangeTab = async (tab, id = null) => {
		if (id) {
			const response = await getAiAlertActivities(id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setAlertDetails(response.aiAlertActivities);
		}
		setCurrentTab(tab);
	};
	return (
		<>
			<Tabs activeIndex={currentTab}>
				<TabPanels>
					<TabPanel>
						<div className='patient-summary-wrapper  measurements-alerts-wrapper'>
							<h3>{translate('artificialIntelligence')}</h3>
							{aiAlerts.length === 0 && !isLoading && (
								<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
									<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
								</div>
							)}
							{aiAlerts.length > 0 && !isLoading && (
								<>
									<div className='patient-summary-table-wrapper'>
										{aiAlerts.map(item => (
											<div key={item.id} className='patient-request-list-item cursor-pointer'>
												<div className='alert-icon'>
													{aiAlertType(user.darkMode).find(el => el.type === item.aiAlertType.name)?.icon || (
														<PatientPosition color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
													)}
												</div>
												<div>
													<p>{intl.formatMessage({ id: item.aiAlertType.name })}</p>
													<p className={classNames('alert-time', isArabic ? 'left-position' : '')}>
														<img src={`${imageUrl}Time.svg`} alt='ico' />
														{formattedDate(item.dateCreated)}
													</p>
												</div>
												<div className='flex-1 flex flex-justify-center right-align-items'>
													<Button
														className={classNames('alert-view-details-btn', isArabic ? 'left-position' : '')}
														onClick={() => handleChangeTab(tabs.DETAILS, item.id)}
														imgIcon={`${imageUrl}View-Details.svg`}
														text={translate('viewDetails')}
													/>
												</div>
											</div>
										))}
									</div>
									<Pagination
										totalCount={pagination.totalCount}
										pageSize={pagination.pageSize}
										pageIndex={pagination.pageIndex}
										onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
									/>
								</>
							)}

							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					</TabPanel>
					{alertDetails && (
						<TabPanel>
							<div
								className={classNames(
									`view-request-history-wrapper patient-summary-wrapper ${!alertDetails.length ? 'center-data' : ''}`
								)}>
								<div className='flex flex-align-center left-margin-s'>
									<Button
										className='alert-details-btn'
										onClick={() => handleChangeTab(tabs.ARTIFICIAL_INTELLIGENCE)}
										icon='chevron_left'
									/>
									<p className='bold'>{translate('alertDetails')}</p>
								</div>
								<AlertHistoryModal alertDetails={alertDetails} />
							</div>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
		</>
	);
};

export default AiAlerts;
