import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { UserRoles } from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const DoctorWaitingRoom = lazyWithRetry(() => import('containers/PrimaryCare/Doctor/DoctorWaitingRoom.jsx'));
const LabWaitingRoom = lazyWithRetry(() => import('containers/PrimaryCare/LaboratoryAnalysis/LabWaitingRoom.jsx'));
const RadiologyWaitingRoom = lazyWithRetry(() => import('containers/PrimaryCare/Radiology/RadiologyWaitingRoom.jsx'));
const InfusionWaitingRoom = lazyWithRetry(() => import('containers/PrimaryCare/Infusion/InfusionWaitingRoom.jsx'));
const InjectionWaitingRoom = lazyWithRetry(() => import('containers/PrimaryCare/Injection/InjectionWaitingRoom.jsx'));

const WaitingRoomHomePrimaryCare = () => {
	const userSession = useSelector(state => state.user.userSession);
	return (
		<Suspense
			fallback={
				<MainLayout>
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				</MainLayout>
			}>
			{!userSession.isInBreak && (
				<>
					{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.LABORATORY.type && <LabWaitingRoom />}
					{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.RADIOLOGY.type && (
						<RadiologyWaitingRoom />
					)}
					{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.INJECTION_ROOM.type && (
						<InjectionWaitingRoom />
					)}
					{getUserRole() === UserRoles.NURSE && userSession.channelTypeId === RoomTypes.INFUSION_ROOM.type && (
						<InfusionWaitingRoom />
					)}
					{getUserRole() === UserRoles.DOCTOR && <DoctorWaitingRoom />}
				</>
			)}
			{userSession.isInBreak && <></>}
		</Suspense>
	);
};

export default WaitingRoomHomePrimaryCare;
