import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';
import RapidResponse from 'icons/Monitoring/RapidResponse.jsx';
import useConferenceConfigurations from 'calls/hooks/useConferenceConfigurations.js';
import translate from 'i18n-translations/translate.jsx';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isShownInMinimizedView: boolean }, never>}
 */
const StyledDeviceControlsLockedNotification = styled.div`
	z-index: 3;
	position: absolute;
	position: ${props => (props.$isShownInMinimizedView ? 'relative' : 'absolute')};
	top: ${LightTheme.spacing[2]}px;
	padding: ${LightTheme.spacing[2]}px;
	border-radius: ${LightTheme.spacing[1]}px;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	text-align: center;
	svg {
		margin-right: ${LightTheme.spacing[1]}px;
	}
	p {
		margin: 0;
		padding: 0;
		font-size: 14px;
		color: ${LightTheme.colors.grayZero};
	}
`;

/**
 * @param {object} props
 * @param {boolean} [props.isMinimizedView]
 */
const DeviceControlsLockedNotification = ({ isMinimizedView = false }) => {
	const conferenceConfigs = useConferenceConfigurations();

	return (
		<StyledDeviceControlsLockedNotification $isShownInMinimizedView={isMinimizedView}>
			{!isMinimizedView && <RapidResponse height={20} width={20} color={LightTheme.colors.grayZero} />}
			<p>
				{translate('deviceControlsLockedMessage', {
					value: conferenceConfigs.deviceControlsLockedNotification.participantName,
				})}
			</p>
		</StyledDeviceControlsLockedNotification>
	);
};

export default DeviceControlsLockedNotification;
