import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { HealthSystemType } from 'constants/enums.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid } from 'components/index.js';
import { Loader } from 'calls/components/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const DoctorCheckIn = lazyWithRetry(() => import('containers/PrimaryCare/Doctor/DoctorCheckIn.jsx'));
const DoctorHomePage = lazyWithRetry(() => import('views/DoctorHomePage.jsx'));

const DoctorHomePageWrapper = () => {
	const workflowTypeId = useSelector(state => state.user.userSession.healthSystem.workflowTypeId);
	return (
		<Suspense
			fallback={
				<MainLayout>
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				</MainLayout>
			}>
			{workflowTypeId === HealthSystemType.PRIMARY_CARE && <DoctorCheckIn />}
			{workflowTypeId !== HealthSystemType.PRIMARY_CARE && <DoctorHomePage />}
		</Suspense>
	);
};

export default DoctorHomePageWrapper;
