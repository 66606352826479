import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CameraTiltDirection, ZoomDirection, CameraType, CameraZoomLevels, CameraTiltAction } from 'constants/enums.js';
import classNames from 'classnames';
import Button from 'components/Button.jsx';

const Tilt = ({ disabledDirections = {}, ...props }) => {
	const [previousTiltAction, setPreviousTiltAction] = useState(null);

	const sendTiltEvent = (direction, action) => {
		if (
			props.disabled ||
			(props.cameraType === CameraType.HELLO && disabledDirections[direction]) ||
			(previousTiltAction === CameraTiltAction.STOP && action === CameraTiltAction.STOP)
		) {
			return;
		}
		props.tiltHandler(direction, action);
		setPreviousTiltAction(action);
	};

	const zoomUpperLimit = props.cameraType === CameraType.HELLO ? CameraZoomLevels.HELLO_MAX : CameraZoomLevels.HUDDLE_MAX;

	return (
		<div className='tilt_container'>
			<div className='tilt_figure'>
				<figure>
					<div
						className={classNames(
							'btn',
							'btn_up',
							props.disabled || (props.cameraType === CameraType.HELLO && disabledDirections[CameraTiltDirection.UP])
								? 'btn-disabled'
								: ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.UP, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.UP, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.UP, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<div
						className={classNames(
							'btn',
							'btn_right',
							props.disabled || (props.cameraType === CameraType.HELLO && disabledDirections[CameraTiltDirection.RIGHT])
								? 'btn-disabled'
								: ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.RIGHT, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.RIGHT, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.RIGHT, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<div
						className={classNames(
							'btn',
							'btn_left',
							props.disabled || (props.cameraType === CameraType.HELLO && disabledDirections[CameraTiltDirection.LEFT])
								? 'btn-disabled'
								: ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.LEFT, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.LEFT, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.LEFT, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<div
						className={classNames(
							'btn',
							'btn_down',
							props.disabled || (props.cameraType === CameraType.HELLO && disabledDirections[CameraTiltDirection.DOWN])
								? 'btn-disabled'
								: ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.DOWN, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.DOWN, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.DOWN, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<figcaption>
						{props.cameraType === CameraType.HUDDLE && (
							<span
								className={classNames('reset-camera cursor-pointer', props.disabled ? 'disabled-reset' : '')}
								onClick={props.cameraType === CameraType.HUDDLE && !props.disabled ? props.onHuddleCamReboot : null}>
								<i className='material-icons-outlined'>refresh</i>
							</span>
						)}
					</figcaption>
				</figure>
			</div>
			<div className='tilt__zoom'>
				<Button
					className={classNames('tilt__zoom--out', props.cameraZoomLevel === 0 || props.disabled ? 'disabled-zoom' : '')}
					onClick={() => {
						if (props.cameraZoomLevel === 0 || props.disabled) {
							return;
						}
						props.updateZoomLevel(ZoomDirection.DECREASE);
					}}
					icon='remove'
				/>
				<p className='tilt__zoom--text'>Zoom {props.cameraZoomLevel !== 0 ? `x${props.cameraZoomLevel / 20}` : ''}</p>
				<Button
					className={classNames(
						'tilt__zoom--in',
						props.cameraZoomLevel === zoomUpperLimit || props.disabled ? 'disabled-zoom' : ''
					)}
					onClick={() => {
						if (props.cameraZoomLevel === zoomUpperLimit || props.disabled) {
							return;
						}
						props.updateZoomLevel(ZoomDirection.INCREASE);
					}}
					icon='add'
				/>
			</div>
		</div>
	);
};

Tilt.propTypes = {
	disabledDirections: PropTypes.shape({
		[CameraTiltDirection.UP]: PropTypes.bool,
		[CameraTiltDirection.DOWN]: PropTypes.bool,
		[CameraTiltDirection.RIGHT]: PropTypes.bool,
		[CameraTiltDirection.LEFT]: PropTypes.bool,
	}),
	tiltHandler: PropTypes.func.isRequired,
	cameraZoomLevel: PropTypes.number.isRequired,
	cameraType: PropTypes.string.isRequired,
	updateZoomLevel: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default Tilt;
