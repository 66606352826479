import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid, Loader } from 'components/index.js';
const CareNotifications = lazyWithRetry(() => import('views/CareNotifications/CareNotifications.jsx'));
const CareNotificationsMayo = lazyWithRetry(() => import('views/CareNotifications/CareNotificationsMayo.jsx'));

const CareNotificationsWrapper = () => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	return (
		<Suspense
			fallback={
				<MainLayout>
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				</MainLayout>
			}>
			{isNewExperience ? <CareNotificationsMayo /> : <CareNotifications />}
		</Suspense>
	);
};

export default CareNotificationsWrapper;
