import { useState } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference } from 'calls/hooks/index.js';
import { ButtonType, InterpreterCallTypes } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { dialOutTranslator } from 'api/sipProvider.js';
import CustomSelect from 'components/Select.jsx';
import { getInterpreterCallTypeOptions } from 'calls/helpers/index.js';

/**
 * @param {object} props
 * @param {object} props.interpretationConfig
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {(participantId: string, message: string) => void} props.removeParticipantOnNoAnswer
 */

const InviteAmnView = ({ interpretationConfig, onDismiss, setError, removeParticipantOnNoAnswer }) => {
	const conference = useConference();
	const intl = useIntl();
	const callTypes = getInterpreterCallTypeOptions(intl);
	const [selectedCallTypeId, setSelectedCallTypeId] = useState(
		interpretationConfig?.callType
			? callTypes.find(item => interpretationConfig.callType === item.value).value
			: callTypes[0].value
	);

	const inviteDialOutTranslator = async () => {
		const response = await dialOutTranslator(
			interpretationConfig.sipEndpoint,
			conference.conferenceId,
			selectedCallTypeId === InterpreterCallTypes.AUDIO
		);
		if (response.error) {
			setError(translate('serviceProvicerNoAnswer'));
			return;
		}
		removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
		onDismiss();
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					<div className='interpreter-desc'>
						<p>{translate('inviteInterpreterDesc')}</p>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={intl.formatMessage({ id: 'callType' })}
							name='selectCallType'
							items={callTypes}
							valueField='value'
							textField='label'
							placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'callType' })}`}
							value={selectedCallTypeId}
							onSelect={event => setSelectedCallTypeId(+event.target.value)}
						/>
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button type='submit' onClick={inviteDialOutTranslator} variant={ButtonType.SUBMIT}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteAmnView;
