const BloodPressure = props => (
	<svg width={22} height={22} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<ellipse cx={15.8333} cy={9.16927} rx={3.33333} ry={3.33333} stroke='currentColor' strokeWidth={1.5} />
		<ellipse cx={15.8333} cy={9.16927} rx={0.833333} ry={0.833333} stroke='currentColor' strokeWidth={1.5} />
		<path d='M15.834 5.83594V6.66927' stroke='currentColor' strokeWidth={1.5} />
		<path d='M15.834 11.668V12.5013' stroke='currentColor' strokeWidth={1.5} />
		<path d='M12.5 9.16797L13.3333 9.16797' stroke='currentColor' strokeWidth={1.5} />
		<path d='M18.334 9.16797L19.1673 9.16797' stroke='currentColor' strokeWidth={1.5} />
		<path d='M16.459 8.54427L17.2923 7.71094' stroke='currentColor' strokeWidth={1.5} />
		<path
			d='M13.7513 11.668H6.16797C5.0634 11.668 4.16797 10.7725 4.16797 9.66797V3.66797C4.16797 2.5634 5.0634 1.66797 6.16797 1.66797H13.8346C14.9392 1.66797 15.8346 2.5634 15.8346 3.66797V5.83464'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M10.0013 11.668V15.5013C10.0013 16.6059 9.10587 17.5013 8.0013 17.5013H3.66797C2.5634 17.5013 1.66797 16.6059 1.66797 15.5013V10.418'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M0.833984 8.33333C0.833984 7.8731 1.20708 7.5 1.66732 7.5V7.5C2.12756 7.5 2.50065 7.8731 2.50065 8.33333V9.58333C2.50065 10.0436 2.12756 10.4167 1.66732 10.4167V10.4167C1.20708 10.4167 0.833984 10.0436 0.833984 9.58333V8.33333Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
	</svg>
);
export default BloodPressure;
