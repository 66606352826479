import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
	LocalTrackFactory,
	MediaDevicesController,
	MediaDevicesUpdated,
	TrackChangeEvent,
	Mic,
	Cam,
	ScreenShare,
} from '@solaborate/calls/webrtc';
import { Modal, Select, IconButton, ParticipantAudioLevel } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { attachSinkId, getSavedStreamSettings, imageUrlToBase64 } from 'calls/helpers/index.js';
import translate from 'i18n-translations/translate.jsx';
import { MediaDeviceKinds, WindowSize } from 'calls/enums/index.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import { getStorage, isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import { loadTfLite, StreamBackgroundEffect } from 'calls/views/TrackWithBackground.jsx';
import { CompanySettings, getConfig, UserSettings } from 'constants/configurationEnums.js';
import { getBackgroundImages } from 'api/configurations.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { VirtualBackgroundTypes } from 'calls/views/VirtualBackgroundProvider.jsx';
import { getBlobSasPicture } from 'api/doctors.js';
import classNames from 'classnames';
import { setUserHealthSystemPreferences, getUserHealthSystemPreferences } from 'api/users.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import Alert from 'components/Alert.jsx';
import SpinLoader from 'icons/Monitoring/SpinLoader.jsx';
import CustomButton from 'components/Button.jsx';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isOutsideOfCall: boolean, $isFromMonitoring: boolean }, never>}
 */
const StyledStreamSettings = styled.div`
	section {
		background: ${LightTheme.colors.grayZero};
		header {
			border-bottom: 1px solid ${LightTheme.colors.grayOne};
			h1 {
				color: ${LightTheme.colors.grayFive};
			}
			button {
				span {
					color: ${LightTheme.colors.grayFive};
				}
			}
		}
	}

	select {
		padding-right: ${LightTheme.spacing[6]}px !important;
	}

	> div {
		video {
			max-width: 200px;
			max-height: 150px;
			transform: scaleX(-1);
		}

		main {
			> header {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: ${LightTheme.spacing[3]}px;
				background: ${LightTheme.colors.graySix};
				border-bottom: none;
			}

			> div {
				&:last-of-type label button {
					padding: 0;
					box-shadow: none;
					span {
						font-size: 18px;
					}
				}

				button {
					span {
						text-align: center;
						i {
							font-size: 22px !important;
						}
					}
				}
			}

			label {
				color: ${LightTheme.colors.grayFive};
				button {
					padding: 0;
					span {
						color: ${LightTheme.colors.grayFive};
					}
				}
			}

			select {
				appearance: none;
				background-color: ${LightTheme.colors.grayZero};
				color: ${LightTheme.colors.grayFive};
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
			}
		}
	}

	> section {
		width: 960px;
		display: flex;
		align-items: center;
		margin: auto;

		h1,
		h2,
		p {
			margin: 0;
			padding: 0;
			color: ${LightTheme.colors.grayZero};
		}

		h1 {
			font-size: 18px;
			margin-bottom: ${LightTheme.spacing[2]}px;
		}

		h2 {
			font-size: 16px;
		}

		> main {
			position: relative;
			flex: 2;
			aspect-ratio: 16/9;
			background: ${LightTheme.colors.graySix};
			border-radius: ${LightTheme.borderRadius.base}px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			overflow: hidden;

			> video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			> header,
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				padding: ${LightTheme.spacing[5]}px;

				> button {
					margin-right: ${LightTheme.spacing[2]}px;
				}
			}

			> header {
				align-items: center;
				flex-direction: column;

				> div {
					margin-bottom: ${LightTheme.spacing[2]}px;
				}
			}

			> aside {
				position: absolute;
				top: ${LightTheme.spacing[1]}px;
				right: ${LightTheme.spacing[1]}px;
			}
		}

		> aside {
			padding: ${LightTheme.spacing[8]}px;
			flex: 1;
		}
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		min-height: 100vh;
		> section {
			flex-direction: column;
			padding: ${LightTheme.spacing[5]}px;

			> main {
				width: 100%;
			}

			> aside {
				padding: ${LightTheme.spacing[7]}px ${LightTheme.spacing[5]}px;
			}
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			section {
				background: ${DarkTheme.colors.grayThree};

				header {
					border-bottom: 1px solid ${DarkTheme.colors.grayFour};

					h1 {
						color: ${DarkTheme.colors.grayFive};
					}

					button span {
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}

			div {
				main {
					> header {
						background: ${DarkTheme.colors.grayFour};
					}

					label {
						color: ${DarkTheme.colors.grayFive};

						button span {
							color: ${DarkTheme.colors.grayFive};
						}
					}

					select {
						background-color: ${DarkTheme.colors.grayFour};
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}
		`}

	${props =>
		props.$isOutsideOfCall &&
		css`
			width: 100%;
			height: calc(100vh - 125px);
			border-left: 1px solid ${props.$isDarkMode ? DarkTheme.colors.grayFour : 'var(--gray-2)'};
			text-align: start;
			overflow: auto;
			> div {
				position: unset;
				width: 100%;
				max-width: 100%;
				height: 100%;
			}
		`}

	${props =>
		props.$isFromMonitoring &&
		css`
			> div:first-of-type {
				position: unset;
				background: none;
				height: 100%;
				> section {
					box-shadow: unset;
					width: 100%;
					max-width: 100%;
					> header {
						display: none;
					}
					> div {
						padding: 0;
						> main {
							> header {
								padding: 0;
							}
							> div {
								> label {
									button {
										padding: 0 !important;
										background: unset !important;
									}
								}
								> select {
									box-shadow: unset;
									border: 1px solid var(--gray-8-o05);
								}
							}
							.stream-settings-wrapper {
								> button {
									padding: 0 !important;
									background: unset !important;
									min-width: 50px;
									border: 1px solid ${props => (props.$isDarkMode ? 'var(--gray-0-o10)' : 'var(--gray-8-o05)')} !important;
									> img {
										border-radius: var(--spacing-s);
									}
								}
							}
							.virtual-background-buttons-container {
								> button {
									background-color: var(--blue-2) !important;
									border-color: var(--blue-2) !important;
								}
							}
						}
					}
				}
			}
		`}
`;

/**
 * @typedef {import('@solaborate/calls/webrtc').LocalTrack} LocalTrack
 * @param {object} props
 * @param {object} [props.localParticipant]
 * @param {() => void} props.onDismiss
 * @param {(localTracks: LocalTrack[]) => void} [props.setLocalTracks]
 * @param {LocalTrack[]} [props.localTracks]
 * @param {() => void} [props.createLocalTracks]
 * @param {string} [props.position]
 * @param {boolean} [props.isOutsideOfCall]
 * @param {boolean} [props.isFromMonitoring]
 */

const StreamSettingsView = ({
	localParticipant,
	onDismiss,
	setLocalTracks,
	localTracks,
	createLocalTracks,
	position,
	isOutsideOfCall = false,
	isFromMonitoring = false,
}) => {
	const { current: mediaDevicesController } = useRef(new MediaDevicesController());
	let { current: modelOptions } = useRef({
		height: 144,
		width: 256,
		virtualBackground: {
			backgroundEffectEnabled: false,
			backgroundType: 'none',
		},
	});

	const [tracks, setTracks] = useState({});
	const [mediaDevices, setMediaDevices] = useState(mediaDevicesController.filteredMediaDevices);

	const localTrackFactory = useRef(null);
	const previewVideoRef = useRef(null);
	const audioRefOutput = useRef(null);

	const intl = useIntl();
	const [streamEffect, setStreamEffect] = useState(null);
	const [selectedBackground, setSelectedBackground] = useState({ url: null, pictureUrl: null });
	const [isLoading, setIsLoading] = useState(false);
	const [isBackgroundImgsLoading, setIsBackgroundImgsLoading] = useState(false);
	const [error, setError] = useState(null);

	const [backgrounds, setBackgrounds] = useState([
		{
			id: 6,
			description: translate('filterNone'),
			onClick: stream => removeBackground(stream),
			url: VirtualBackgroundTypes.NONE,
		},
		{
			id: 5,
			description: translate('filterBlur'),
			onClick: stream => blurBackground(stream),
			url: VirtualBackgroundTypes.BLUR,
		},
	]);

	const conferenceConfigs = useConferenceConfigurations();
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const userSession = useSelector(state => state.user.userSession);
	const [loadingBgIds, setLoadingBgIds] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		const audioOutputId = getSavedStreamSettings()[MediaDeviceKinds.AUDIO_OUTPUT];
		if (audioOutputId) {
			attachSinkId(audioRefOutput.current, audioOutputId);
		}
		const fetchUserSettings = async () => {
			const response = await getUserHealthSystemPreferences(userSession.healthSystem.id);
			if (response.error) {
				return;
			}
			let obj = {};
			response.teamMemberSettings.forEach(item => {
				if (Object.values(UserSettings).includes(item.settingTypeId)) {
					obj[item.settingTypeId] = item.value;
				}
			});
			dispatch(configurationActionCreators.setUserSettings(obj));
		};
		fetchUserSettings();
	}, []);

	useEffect(() => {
		if (mediaDevicesController.mediaDevices.length > 0) {
			setMediaDevices(mediaDevicesController.filteredMediaDevices);
		}
	}, [mediaDevicesController.filteredMediaDevices, mediaDevicesController.mediaDevices]);

	useEffect(() => {
		if (localParticipant) {
			localParticipant.localTrackController.on(event => {
				if (event instanceof TrackChangeEvent) {
					setTracks({ ...localParticipant.localTrackController.tracks });
				}
			});

			const videoTrack = localParticipant.localTrackController.tracks[Cam];
			if (videoTrack) {
				previewVideoRef.current.srcObject = new MediaStream([videoTrack.track]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localParticipant]);

	useEffect(() => {
		if (!localParticipant) {
			setStreamSettings();
		}
		return () => {
			if (!localParticipant && !setLocalTracks) {
				localTrackFactory.current.destroy();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localParticipant]);

	useEffect(() => {
		mediaDevicesController.on(event => {
			if (event instanceof MediaDevicesUpdated) {
				setMediaDevices(mediaDevicesController.filteredMediaDevices);
			}
		});
	}, [mediaDevicesController]);

	useEffect(() => {
		const getTracks = async () => {
			if (previewVideoRef?.current?.srcObject) {
				return;
			}
			const videoTrack = tracks[Cam];
			if (!videoTrack) {
				return;
			}
			if (isMobileOrTablet()) {
				const factoryTracksResponse = await localTrackFactory.current.createTracks([Cam]);
				previewVideoRef.current.srcObject = new MediaStream([factoryTracksResponse[0].track]);
				return;
			}
			if (videoTrack && videoTrack instanceof StreamBackgroundEffect) {
				modelOptions = { ...videoTrack.options };
			}
			const tflite = await loadTfLite();
			const sbe = new StreamBackgroundEffect(videoTrack, tflite, modelOptions);
			setStreamEffect(sbe);
			previewVideoRef.current.srcObject = new MediaStream([sbe.track]);
		};
		getTracks();
	}, [tracks]);

	useEffect(() => {
		const videoTrack = localTracks?.[Cam];
		if (videoTrack) {
			previewVideoRef.current.srcObject = new MediaStream([videoTrack]);
		}
	}, [localTracks]);

	const setStreamSettings = async () => {
		const { [MediaDeviceKinds.AUDIO_INPUT]: audioInputId, [MediaDeviceKinds.VIDEO_INPUT]: videoInputId } =
			getSavedStreamSettings();
		/** @type {import('@solaborate/calls/webrtc').Constraints} */
		const constraints = { [Mic]: true, [Cam]: true, [ScreenShare]: false };
		if (audioInputId) {
			constraints.audio = { deviceId: audioInputId };
		}
		if (videoInputId) {
			constraints.video = { deviceId: videoInputId };
		}

		if (localTracks) {
			createLocalTracks();
			return;
		}

		localTrackFactory.current = new LocalTrackFactory(constraints);
		try {
			const factoryTracksResponse = await localTrackFactory.current.createTracks([Mic, Cam]);
			if (factoryTracksResponse) {
				setTracks(factoryTracksResponse.reduce((acc, track) => ({ ...acc, [track.type]: track }), {}));
				if (setLocalTracks) {
					setLocalTracks(factoryTracksResponse);
				}
			}
		} catch (err) {
			setError(err.error.message);
		} finally {
			setIsBackgroundImgsLoading(false);
		}
	};

	const saveStreamSettings = (kind, deviceId) => {
		const savedStreamSettings = getSavedStreamSettings();
		const newSavedStreamSettings = {
			...savedStreamSettings,
			[kind]: deviceId,
		};
		getStorage().setItem('streamSettings', JSON.stringify(newSavedStreamSettings));
	};

	const isDarkMode = useSelector(state => state.user.darkMode);

	useEffect(() => {
		if (streamEffect) {
			const setInitialImage = async () => {
				const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
				const backgroundConfig = getConfig(visualSettings[CompanySettings.DIGITAL_BACKGROUND]);
				const defaultHsBackground = visualSettings[CompanySettings.HS_DEFAULT_BACKGROUND]?.value;

				if (!backgroundConfig.value) {
					setIsBackgroundImgsLoading(false);
					return;
				}
				const response = await getBackgroundImages(getCompanyId(), userSession.healthSystem.id);
				if (response.error) {
					setError(response.error.message);
					return;
				}

				const ids = response.map((_, index) => index + 1);
				setLoadingBgIds(ids);
				const images = await Promise.all(response.map((item, index) => mapBlob(item.pictureUrl, index)));
				if (images) {
					setBackgrounds(prevState => [...prevState, ...images]);
				}
				setIsBackgroundImgsLoading(false);
				setLoadingBgIds([]);

				if (callBackground === VirtualBackgroundTypes.BLUR) {
					blurBackground();
					setIsBackgroundImgsLoading(false);
					return;
				}

				if (callBackground === VirtualBackgroundTypes.NONE) {
					removeBackground();
					setIsBackgroundImgsLoading(false);
					return;
				}

				if (![VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(callBackground) && callBackground) {
					getBackground(callBackground, images);
					return;
				}

				if (defaultHsBackground === VirtualBackgroundTypes.BLUR) {
					blurBackground();
					setIsBackgroundImgsLoading(false);
					return;
				}

				if (defaultHsBackground === VirtualBackgroundTypes.NONE) {
					removeBackground();
					setIsBackgroundImgsLoading(false);
					return;
				}

				if (![VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(defaultHsBackground)) {
					getBackground(defaultHsBackground, images);
				}
			};
			setInitialImage();
		} else {
			setIsBackgroundImgsLoading(false);
		}
	}, [streamEffect]);

	const getBackground = async (background, images) => {
		let blobResponse = {};
		if (background) {
			blobResponse = await getBlobSasPicture(background, 'team-call-background-pictures');
		}
		if (!blobResponse.error && !blobResponse.errorResponse) {
			const found = images.find(item => item.pictureUrl === background);
			if (found) {
				onSelectBackground(blobResponse.result.uri.uri, background);
				return;
			}
		}
		setSelectedBackground(prevState => ({ ...prevState, url: VirtualBackgroundTypes.NONE }));
	};

	const mapBlob = async (pictureUrl, index) => {
		const response = await getBlobSasPicture(pictureUrl, 'team-call-background-pictures');
		if (!response.hasSucceeded) {
			setError(response.errorResponse.message);
			return {};
		}
		if (response.error || !response.hasSucceeded) {
			setError(response.error.message);
			return {};
		}
		const imgToUpload = { url: response.result.uri.uri, id: index, pictureUrl };
		return imgToUpload;
	};

	const onCancel = () => {
		if (streamEffect) {
			streamEffect.stop();
		}
		onDismiss();
	};

	const onSelectBackground = async (url, pictureUrl) => {
		const base64Image = await imageUrlToBase64(url);
		setSelectedBackground({ url, pictureUrl });
		if (streamEffect) {
			streamEffect.changeBackground(base64Image);
		}
	};

	const removeBackground = (stream = streamEffect) => {
		setSelectedBackground({ url: VirtualBackgroundTypes.NONE, pictureUrl: null });
		if (stream) {
			stream.removeBackground();
		}
		getStorage().setItem('virtualBackground', 'none');
	};

	const blurBackground = (stream = streamEffect) => {
		setSelectedBackground({ url: VirtualBackgroundTypes.BLUR, pictureUrl: null });
		if (stream) {
			stream.blurBackground(20);
		}
		getStorage().setItem('virtualBackground', 'blur');
	};

	const onApply = async () => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		const backgroundValue = selectedBackground?.pictureUrl ?? selectedBackground.url;
		const dataToSend = {
			teamSettings: [
				{
					settingTypeId: UserSettings.CALL_BACKGROUND,
					value: backgroundValue,
				},
			],
		};
		const response = await setUserHealthSystemPreferences(userSession.healthSystem.id, dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		dispatch(
			configurationActionCreators.setUserSettings({
				[UserSettings.CALL_BACKGROUND]: backgroundValue,
			})
		);
		modelOptions = { ...streamEffect.options };
		setIsLoading(false);
		onDismiss();
	};

	return (
		<StyledStreamSettings $isDarkMode={isDarkMode} $isOutsideOfCall={isOutsideOfCall} $isFromMonitoring={isFromMonitoring}>
			<Modal onDismiss={onDismiss} title={intl.formatMessage({ id: 'streamSettingsModalTitle' })} position={position}>
				<Modal.Content>
					<header>
						<video ref={previewVideoRef} autoPlay muted playsInline />
					</header>
					<Select>
						<Select.Label>{translate('camera')}</Select.Label>
						<Select.Field
							onChange={evt => {
								saveStreamSettings(MediaDeviceKinds.VIDEO_INPUT, evt.target.value);
								if (localParticipant) {
									localParticipant.localTrackController.changeTrackConstraints(Cam, {
										deviceId: { exact: evt.target.value },
									});
								} else {
									setStreamSettings();
								}
							}}>
							{mediaDevices[MediaDeviceKinds.VIDEO_INPUT]?.map(({ label, deviceId }) => (
								<Select.Option
									key={deviceId}
									selected={getSavedStreamSettings()[MediaDeviceKinds.VIDEO_INPUT] === deviceId}
									value={deviceId}>
									{label}
								</Select.Option>
							))}
						</Select.Field>
					</Select>
					<Select>
						<Select.Label>
							{translate('microphone')}&nbsp;&nbsp;
							{localTracks?.[Mic] && <ParticipantAudioLevel track={localTracks[Mic]} />}
							{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
						</Select.Label>
						<Select.Field
							onChange={evt => {
								saveStreamSettings(MediaDeviceKinds.AUDIO_INPUT, evt.target.value);
								if (localParticipant) {
									localParticipant.localTrackController.changeTrackConstraints(Mic, {
										deviceId: { exact: evt.target.value },
									});
								} else {
									setStreamSettings();
								}
							}}>
							{mediaDevices[MediaDeviceKinds.AUDIO_INPUT].map(({ label, deviceId }) => (
								<Select.Option
									key={deviceId}
									selected={getSavedStreamSettings()?.[MediaDeviceKinds.AUDIO_INPUT] === deviceId}
									value={deviceId}>
									{label}
								</Select.Option>
							))}
						</Select.Field>
					</Select>
					<Select>
						<Select.Label>
							{translate('streamSettingsSpeaker')}&nbsp;
							<IconButton
								background='transparent'
								color={LightTheme.colors.grayFive}
								icon='play_circle'
								onClick={() => audioRefOutput.current.play()}
							/>
						</Select.Label>
						<Select.Field
							onChange={async evt => {
								const selectedValue = evt.target.value;
								saveStreamSettings(MediaDeviceKinds.AUDIO_OUTPUT, selectedValue);
								conferenceConfigs.setSinkId(selectedValue);
								await attachSinkId(audioRefOutput.current, selectedValue);
							}}>
							{mediaDevices[MediaDeviceKinds.AUDIO_OUTPUT].map(({ label, deviceId }) => (
								<Select.Option
									key={deviceId}
									selected={getSavedStreamSettings()[MediaDeviceKinds.AUDIO_OUTPUT] === deviceId}
									value={deviceId}>
									{label}
								</Select.Option>
							))}
						</Select.Field>
						<Select.Description>{translate('usingDifferentMic')}</Select.Description>
					</Select>
					<audio ref={audioRefOutput} src='https://static.solaborate.com/global/brand/calls/ring.mp3' />
					{!isMobileOrTablet() && (
						<>
							{!isBackgroundImgsLoading && tracks[Cam] && (
								<div className='stream-settings-wrapper'>
									{backgrounds.map(({ url, id, pictureUrl, onClick, description }) => (
										<>
											{onClick && (
												<CustomButton
													key={id}
													type='button'
													onClick={() => onClick(streamEffect)}
													className={
														selectedBackground.url === url
															? 'virtual-background-image-selected'
															: 'virtual-background-image-button'
													}
													border='1px solid var(--gray-3)'
													borderRadius='var(--spacing-s)'
													marginRight='var(--spacing-s)'
													marginTop='var(--spacing-s)'
													svgIcon={
														<span className='virtual-background-text' style={{ margin: 'auto' }}>
															{url === VirtualBackgroundTypes.BLUR && <i className='material-icons'>blur_on</i>}
															{url === VirtualBackgroundTypes.NONE && <i className='material-icons'>no_accounts</i>}
															<p className='no-margin no-padding'>{description}</p>
														</span>
													}
												/>
											)}
											{!onClick && (
												<CustomButton
													border='1px solid var(--gray-3)'
													marginRight='var(--spacing-s)'
													marginTop='var(--spacing-s)'
													key={id}
													type='button'
													onClick={() => onSelectBackground(url, pictureUrl)}
													className={
														selectedBackground.pictureUrl === pictureUrl
															? 'virtual-background-image-selected'
															: 'virtual-background-image-button'
													}
													background='var(--dark-theme-gray-2)'
													svgIcon={<img src={url} alt='Virtual background thumbnail' className='stream-settings-img' />}
												/>
											)}
										</>
									))}
									{loadingBgIds.map(() => (
										<CustomButton
											className='virtual-background-image-button'
											svgIcon={<SpinLoader color={LightTheme.colors.blueOne} />}
										/>
									))}
								</div>
							)}
							{isBackgroundImgsLoading && <SpinLoader />}
							{tracks[Cam] && (
								<div className='virtual-background-buttons-container'>
									{!isFromMonitoring && (
										<CustomButton
											className={classNames(conferenceConfigs.isDarkMode ? 'virtual-background-dark-cancel-button' : '')}
											variant='white'
											text={translate('cancel')}
											onClick={onCancel}
										/>
									)}
									<CustomButton
										text={translate('selectBackground')}
										className={classNames(conferenceConfigs.isDarkMode ? 'virtual-background-dark-select-bg-button' : '')}
										onClick={onApply}
										isLoading={isLoading}
									/>
								</div>
							)}
						</>
					)}
				</Modal.Content>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</Modal>
		</StyledStreamSettings>
	);
};

export default StreamSettingsView;
