import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { HealthSystemType } from 'constants/enums.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid, Loader } from 'components/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const DigitalClinicianPrimaryCareCheckIn = lazyWithRetry(
	() => import('containers/PrimaryCare/DigitalClinician/DigitalClinicianPrimaryCareCheckIn.jsx')
);
const CheckInAPatient = lazyWithRetry(() => import('views/CheckInAPatient/CheckInAPatient.jsx'));

const CheckInHome = () => {
	const userSession = useSelector(state => state.user.userSession);
	return (
		<Suspense
			fallback={
				<MainLayout>
					<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
						<Loader />
					</Grid>
				</MainLayout>
			}>
			{userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE && <DigitalClinicianPrimaryCareCheckIn />}
			{userSession.healthSystem.workflowTypeId !== HealthSystemType.PRIMARY_CARE && <CheckInAPatient />}
		</Suspense>
	);
};

export default CheckInHome;
