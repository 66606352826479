import { AllConnections, conference, enums, StatsCollector } from '@solaborate/calls';
import {
	BaseRemoteTrackController,
	Cam,
	Configuration,
	LocalTrackFactory,
	MediaDevicesController,
	Mic,
} from '@solaborate/calls/webrtc';
import { useSelector } from 'react-redux';
import { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { v4 } from 'uuid';
import { sendStats } from 'api/callStats.js';
import { getDevice, getUserDevices } from 'api/e2eeDevices.js';
import { getIceServers } from 'api/iceServers.js';
import { ConferenceConfigurationsProvider } from 'calls/ConferenceConfigurationsProvider.jsx';
import { defaultConstraints } from 'calls/constants/index.js';
import { IceTransportTypes, MediaDeviceKinds, StartQueryStringKeys } from 'calls/enums/index.js';
import { callTypeToTrackTypes, getSavedStreamSettings } from 'calls/helpers/index.js';
import LocalParticipant from 'calls/LocalParticipant.js';
import ParticipantFactory from 'calls/ParticipantFactory.js';
import SocketWrapper from 'calls/SocketWrapper.js';
import {
	CallerNameVariant,
	CallRoutingValues,
	CompanyCallSettings,
	CompanySettings,
	RoundingSettings,
} from 'constants/configurationEnums.js';
import { CallTypes, Roles, SourceAppType, UserRoles } from 'constants/enums.js';
import { getCompanyId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { getConfigurationValue, getStorage, isSessionEhr } from 'infrastructure/helpers/commonHelpers.js';
import IndexDb from 'infrastructure/indexDb/db.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import MaskedTrackController from 'calls/views/MaskedTrackController.jsx';

/** @type {import('react').Context<import('@solaborate/calls').conference.Conference<import('calls/RemoteParticipant.js').default, LocalParticipant, SocketWrapper>>} */
export const ConferenceCtx = createContext(null);

/**
 * @param {object} props
 * @param {any} props.children
 * @param {object} props.conferenceInfo
 * @param {import('@solaborate/calls').types.CallType} props.conferenceInfo.callType
 * @param {string} [props.conferenceInfo.conferenceId]
 * @param {string} [props.conferenceInfo.participantId]
 * @param {string} [props.conferenceInfo.conferenceName]
 * @param {string} [props.conferenceInfo.conversationId]
 * @param {import('@solaborate/calls/webrtc').TrackType[]} [props.conferenceInfo.localTrackTypes]
 * @param {boolean} [props.conferenceInfo.isGuest]
 * @param {boolean} [props.conferenceInfo.isMeetingRoom]
 * @param {import('@solaborate/calls/webrtc').Constraints} [props.conferenceInfo.constraints] WebRTC track constraints
 * @param {string} [props.conferenceInfo.interpreterId]
 * @param {string} [props.conferenceInfo.roundingCallType]
 * @param {object[]} [props.conferenceInfo.participants]
 * @param {boolean} [props.conferenceInfo.isAudioOnly]
 */
const ConferenceProvider = ({ children, conferenceInfo }) => {
	const {
		callType,
		conferenceId: conferenceIdToJoin,
		participantId: localParticipantId,
		conferenceName,
		conversationId,
		localTrackTypes,
		isGuest,
		isMeetingRoom,
		interpreterId,
		roundingCallType,
		isAudioOnly,
	} = conferenceInfo;
	const socketIO = useContext(SocketContext);
	const history = useHistory();
	const callSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.callSettings);
	const configurations = useSelector(state => state.configurations);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const isViewPatientShown = roleRoundingConfigurations[RoundingSettings.ViewPatient];
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const initConference = () => {
		// const models = {
		// 	modelOne: `${APP_CONFIG.cdnURL}/healthcare-system/virtual-backgrounds/models/selfie_segmentation_landscape.tflite`,
		// };

		// const processSize = {
		// 	processSizeOne: [144, 256],
		// 	processSizeTwo: [256, 256],
		// };

		const { [MediaDeviceKinds.AUDIO_INPUT]: audioInputId, [MediaDeviceKinds.VIDEO_INPUT]: videoInputId } =
			getSavedStreamSettings();

		defaultConstraints[Mic] = { deviceId: { exact: audioInputId } };
		const oldConstraint = defaultConstraints[Cam];
		if (typeof oldConstraint === 'object') {
			defaultConstraints[Cam] = { ...oldConstraint, deviceId: { exact: videoInputId } };
		} else {
			defaultConstraints[Cam] = { deviceId: { exact: videoInputId } };
		}

		const allConnections = new AllConnections();
		const factory = new LocalTrackFactory(
			defaultConstraints,
			// eslint-disable-next-line no-console
			console,
			false,
			isAudioOnly
		);
		// const factory = new MaskedLocalTrackFactory(
		// 	{
		// 		...constraints,
		// 		...defaultConstraints,
		// 	},
		// 	// eslint-disable-next-line no-console
		// 	console.log,
		// 	true,
		// 	{
		// 		modelKey: models.modelOne,
		// 		processSizeKey: processSize.processSizeOne,
		// 		threshold: 0.1,
		// 		useSIMD: true,
		// 		interpolation: 4,
		// 		lightWrapping: 0,
		// 		strict: false,
		// 		jbfD: 0,
		// 		jbfSigmaC: 2,
		// 		jbfSigmaS: 2,
		// 		jbfPostProcess: 3,
		// 		backgroundImage: getStorage().getItem('virtualBackground'),
		// 	}
		// );

		let userInfo;
		try {
			userInfo = getUserInfo();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			// @ts-ignore
			const link = history.location.state?.conferenceLink;
			if (link) {
				history.replace(link.replace(/^.*\/\/[^/]+/, ''));
			}
			return null;
		}
		const { firstName, lastName, profilePicture, userId, id, prefix } = userInfo;
		const userRole = getUserRole();

		const getProviderName = () => {
			// The name of practioner will come through ehr api, will override local-settings
			const ehrPractioner = localStorage.getItem('practitioner');
			if (ehrPractioner) {
				localStorage.removeItem('practitioner');
				return ehrPractioner;
			}
			const caregiversNameConfig = configurations.adminConfigurableMenu[userRole][RoundingSettings.CaregiversName];
			if (caregiversNameConfig && !caregiversNameConfig.value) {
				return '';
			}
			if (caregiversNameConfig && caregiversNameConfig.value && caregiversNameConfig.variant === CallerNameVariant.CREDENTIALS) {
				return prefix ? `${firstName} ${lastName[0]}., ${prefix}` : `${firstName} ${lastName[0]}.`;
			}
			return prefix ? `${firstName} ${lastName} ${prefix}` : `${firstName} ${lastName}`;
		};

		const name = `${firstName} ${lastName}`;
		const alias = getProviderName();
		const showAlias = [UserRoles.NURSE, UserRoles.DOCTOR].includes(userRole) && alias !== name;

		const mediaDevicesController = new MediaDevicesController();
		const localParticipant = new LocalParticipant(
			{
				id: localParticipantId || v4(),
				name,
				objectId: userId,
				objectType: enums.ObjectTypes.USER,
				picture: profilePicture,
				interpreterId,
				...(showAlias && {
					alias,
				}),
				isAudioOnly,
			},
			{
				connection: allConnections,
				localTrackController: new MaskedTrackController(allConnections, factory, mediaDevicesController),
				remoteTrackController: new BaseRemoteTrackController(allConnections),
			}
		);

		const getRequestedTracks = () => {
			const requestedLocalTracks = [];
			if (isGuest) {
				return localTrackTypes;
			}
			if (getUserRole() === UserRoles.VISITOR || callType === CallTypes.FIRST_RESPONDER) {
				return callTypeToTrackTypes(callType).localTrackTypes;
			}
			if (callType === CallTypes.SECURITY_CAM && isViewPatientShown) {
				return requestedLocalTracks;
			}
			if (callSettings[CompanyCallSettings.PROVIDER_MIC]) {
				requestedLocalTracks.push(Mic);
			}
			if (callSettings[CompanyCallSettings.PROVIDER_CAMERA]) {
				requestedLocalTracks.push(Cam);
			}
			return requestedLocalTracks;
		};

		localParticipant.requestedLocalTracks = getRequestedTracks();
		localParticipant.isGuest = isGuest ?? false;
		const conferenceId = conferenceIdToJoin || v4();
		// @ts-ignore
		const socket = new SocketWrapper(socketIO);
		const participantFactory = new ParticipantFactory(
			factory,
			allConnections,
			conferenceId,
			localParticipant,
			socket,
			callTypeToTrackTypes(callType).remoteTrackTypes
		);
		const indexDb = new IndexDb(id);

		const configuration = new Configuration();
		configuration.getICEServers = getIceServers;
		configuration.iceTransportPolicy =
			getConfigurationValue(companyConfigurations[CompanySettings.CALL_ROUTING_OPTIONS]) === CallRoutingValues.TURN
				? IceTransportTypes.RELAY
				: IceTransportTypes.ALL;

		const roleId = Roles.find(r => r.role === userRole).id;
		const integrationId = history.location.state?.integrationId || +getStorage().getItem('iframe_integration_id');
		const additionalData = [];
		if (roundingCallType) {
			additionalData.push({
				key: StartQueryStringKeys.ROUNDING_CALL_TYPE,
				value: roundingCallType,
			});
		}

		if (isSessionEhr()) {
			const mrn = getStorage().getItem('ehr-mrn');
			const csn = getStorage().getItem('ehr-csn');

			if (mrn) {
				additionalData.push({ key: 'mrn', value: mrn });
				getStorage().removeItem('ehr-mrn');
			}

			if (csn) {
				additionalData.push({ key: 'csn', value: csn });
				getStorage().removeItem('ehr-csn');
			}

			additionalData.push({ key: 'sourceAppType', value: SourceAppType.EPIC });
		}

		return new conference.Conference({
			conferenceId,
			conversationId,
			conferenceName,
			callType,
			isUsingMediaServer: false,
			isMeetingRoom: true,
			isMultiparty: isMeetingRoom,
			isLocked: false,
			isQueuing: false,
			localParticipant,
			socket,
			configuration,
			participantFactory,
			tenantId: getCompanyId(),
			e2eeDeviceProvider: {
				getDeviceById: async deviceId => (await getDevice(deviceId)).device,
				getDevicesByUserId: getUserDevices,
				getMyDevice: async () => {
					const store = await indexDb.keyStore.bulkGet(['deviceId', 'privKey']);
					return {
						deviceId: store[0],
						pubKey: '',
						privKey: store[1],
					};
				},
			},
			collector: new StatsCollector(
				{
					conferenceId,
					participantId: localParticipant.id,
					socket,
					userId: userInfo.id,
					interval: 3000,
				},
				(values, token) => sendStats({ data: { values } }, 5, token)
			),
			instigator: {
				objectId: localParticipant.objectId,
				objectType: localParticipant.objectType,
				role: roleId,
				integrationId,
			},
			...(additionalData.length > 0 && {
				additionalData,
			}),
		});
	};

	const [conferenceData] = useState(initConference);

	return (
		<ConferenceCtx.Provider
			// @ts-ignore
			value={conferenceData}>
			<ConferenceConfigurationsProvider>{children}</ConferenceConfigurationsProvider>
		</ConferenceCtx.Provider>
	);
};

export default ConferenceProvider;
