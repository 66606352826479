import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import EmptyState from 'components/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const DoctorsList = lazyWithRetry(() => import('views/Doctors/Partials/DoctorsList.jsx'));
const DoctorsMain = lazyWithRetry(() => import('views/Doctors/Partials/DoctorsMain.jsx'));

const MyDoctors = () => {
	const selectedDoctor = useSelector(state => state.myDoctors.selectedDoctor);
	return (
		<MainLayout>
			<Grid columns='1fr 3fr' stretch='100%'>
				<aside className='patients-list'>
					<Suspense
						fallback={
							<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
								<Loader />
							</Grid>
						}>
						<DoctorsList />
					</Suspense>
				</aside>
				<main>
					{selectedDoctor && (
						<Suspense
							fallback={
								<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
									<Loader />
								</Grid>
							}>
							<DoctorsMain />
						</Suspense>
					)}
					{!selectedDoctor && (
						<div className='empty-state-wrapper'>
							<EmptyState title={translate('noConnectedDoctor')} image='no-doctor.png'>
								<p>
									{translate('goToThe')} <Link to='doctor-requests'>{translate('requests')}</Link> {translate('toGetStarted')}
								</p>
							</EmptyState>
						</div>
					)}
				</main>
			</Grid>
		</MainLayout>
	);
};

export default MyDoctors;
