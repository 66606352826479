import { useState, Suspense } from 'react';
import { useIntl } from 'react-intl';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import Loader from 'components/Loader.jsx';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const UnAssignedPatients = lazyWithRetry(() => import('views/QueueManagement/UnAssignedPatients.jsx'));
const QueueManagement = lazyWithRetry(() => import('views/QueueManagement/QueueManagement.jsx'));

const QueueManagementWrapper = () => {
	const intl = useIntl();
	const tabList = [
		{ id: 0, text: intl.formatMessage({ id: 'patientQueue' }) },
		{ id: 1, text: intl.formatMessage({ id: 'doctorQueue' }) },
	];
	const [activeTab, setActiveTab] = useState(tabList[0].id);

	return (
		<MainLayout>
			<div className='inner-main-view queue-management'>
				<div className='patient-alerts-wrapper '>
					<div className='flex flex-space-between flex-align-center'>
						<h4>{translate('queueManagement')}</h4>
					</div>
					<Tabs activeIndex={activeTab} onChange={index => setActiveTab(index)}>
						<TabList>
							{tabList.map(tab => (
								<Tab key={tab.id}>{tab.text}</Tab>
							))}
						</TabList>
						<TabPanels>
							<TabPanel>
								<Suspense
									fallback={
										<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
											<Loader />
										</Grid>
									}>
									<UnAssignedPatients />
								</Suspense>
							</TabPanel>
							<TabPanel>
								<Suspense
									fallback={
										<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
											<Loader />
										</Grid>
									}>
									<QueueManagement />
								</Suspense>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</div>
			</div>
		</MainLayout>
	);
};

export default QueueManagementWrapper;
