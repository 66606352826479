const PulseOximeter = props => (
	<svg width={20} height={20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M5 7.5C5 4.73858 7.23858 2.5 10 2.5V2.5C12.7614 2.5 15 4.73858 15 7.5V12.5C15 15.2614 12.7614 17.5 10 17.5V17.5C7.23858 17.5 5 15.2614 5 12.5V7.5Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M11.25 12.918C11.25 13.6083 10.6904 14.168 10 14.168C9.30964 14.168 8.75 13.6083 8.75 12.918C8.75 12.2276 9.30964 11.668 10 11.668C10.6904 11.668 11.25 12.2276 11.25 12.918Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M10.0006 9.64178C6.7192 7.60026 9.14929 5.73744 10.0007 7.17777C10.852 5.73744 13.2821 7.60026 10.0006 9.64178Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
	</svg>
);
export default PulseOximeter;
