import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { WarningIndexes } from 'constants/enums.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import classNames from 'classnames';
import EmptyState from 'components/EmptyState.jsx';
import {
	getAlertDescription,
	getImageUrl,
	getItemComment,
	getItemProperties,
	getTranslatedComment,
} from 'infrastructure/helpers/alertsHelper.jsx';

const AlertHistoryModal = props => {
	const companySettings = useSelector(state => state.company.companySettings);
	const intl = useIntl();
	const darkMode = useSelector(state => state.user?.darkMode);
	const [visibleNotes, setVisibleNotes] = useState([]);

	const activitiesWithIds = props.alertDetails.map((item, index) => ({
		...item,
		id: index + 1,
	}));

	return (
		<>
			{activitiesWithIds?.length === 0 && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
			<div className='full-width'>
				{activitiesWithIds?.length > 0 &&
					activitiesWithIds?.map(item => {
						const properties = getItemProperties(item);
						const comment = getItemComment(properties, intl);

						return (
							<div
								className={classNames('view-request-history-item alert-history flex full-width flex-space-between', {
									'one-alert-history': activitiesWithIds?.length === 1,
								})}>
								<div className='flex'>
									<div className='alert-history-modal-icon-wrapper'>{getImageUrl(item, darkMode)}</div>
									<p className={classNames('alert-history-desc', { 'break-word': props?.isFromMonitoring })}>
										<span>
											{item?.causer?.firstName} {item?.causer?.lastName}
										</span>
										{getAlertDescription(item, companySettings)} {properties?.Name && !properties?.Comment && comment}
										{properties?.Comment && (
											<>
												<div
													className='flex cursor-pointer top-15 flex-align-center'
													onClick={() => {
														setVisibleNotes(prevState =>
															prevState.includes(item.id) ? prevState.filter(id => id !== item.id) : [...prevState, item.id]
														);
													}}>
													<span className='no-padding'>{translate('additionalNotes')}</span>
													<i className='material-icons-outlined font-16'>
														{visibleNotes.find(el => el === item.id) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
													</i>
												</div>
												{visibleNotes.some(el => el === item.id) && <p>{getTranslatedComment(comment, intl)}</p>}
											</>
										)}
										{item.measurementAlertActivityType && props.isMeasurementAlert && properties && (
											<span
												className={`${
													[WarningIndexes.LOW, WarningIndexes.HIGH].includes(properties?.WarningIndex)
														? 'moderate-risk'
														: 'high-risk'
												}`}>
												{properties?.Type &&
													`${intl.formatMessage({ id: stringToCamelCase(properties?.Type) })} 	
									(${props.isMeasurementAlert && properties && `${properties?.Value} ${properties.Unit}`})`}
											</span>
										)}
									</p>
									<p className='alert-time no-padding align-self-end'>
										{!props.isFromMonitoring && <img src={`${healthCareCdnUrl}vsm/Time.svg`} alt='ico' />}
										{formattedDate(item.createdAt)}
									</p>
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default AlertHistoryModal;
