import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import SocketEvents from 'constants/socket-events.js';
import { Mic, Cam, ScreenShare } from '@solaborate/calls/webrtc';
import { participant as CallsParticipant } from '@solaborate/calls';
import LocalParticipant from 'calls/LocalParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { Icon, Dropdown, List, IconButton, ParticipantAudioLevel, StyledParticipantInfo } from 'calls/components/index.js';
import { useConference, useConferenceConfigurations, useControllerTracks, useLocalParticipant } from 'calls/hooks/index.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { UserTypes, ControlsActions } from 'calls/enums/index.js';
import { ClosingOwnerViews } from 'calls/constants/index.js';
import {
	PinIcon,
	UnPinIcon,
	VolumeUpIcon,
	VolumeOffIcon,
	VideocamIcon,
	VideocamOffIcon,
	LiveCaptionsDisabledIcon,
	LiveCaptionsIcon,
	TransferOwnershipIcon,
} from 'calls/icons/index.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getCallsButtonColor, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, }, never>}
 */
const StyledParticipantActions = styled.div`
	max-height: 40px !important;
	width: 230px;
	max-width: 230px;
	display: flex;
	padding: ${LightTheme.spacing[2]}px;
	background: ${props => `
		linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%),
		${props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen}
	`};
	opacity: 0.9;
	justify-content: space-between;
	&:hover {
		opacity: 1;
		transition: all 0.2s linear;
	}
	.default-button {
		background: none;
		span {
			padding: 0;
			background: none;
		}
	}
`;

const StyledMainParticipantInfo = styled.div`
	display: flex;
	overflow: hidden;
	align-items: center;
	svg {
		flex-shrink: 0;
		margin-right: 4px;
		margin-top: -2px;
		height: 18px;
		width: 18px;
	}
	p {
		margin: 0;
		padding: 0;
		color: white;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 *  @param {import('@solaborate/calls/webrtc').TrackType} props.activeTrackType
 */
const MainParticipantControls = ({ mainParticipant, activeTrackType }) => {
	const conference = useConference();
	const conferenceConfigs = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const socketFunctions = useContext(SocketFunctionsContext);
	const isHelloParticipant = mainParticipant instanceof RemoteHelloParticipant;

	const intl = useIntl();
	const tracks = useControllerTracks(
		mainParticipant instanceof LocalParticipant ? localParticipant.localTrackController : mainParticipant.remoteTrackController
	);
	const isCurrentlyPinnedParticipant = conferenceConfigs.pinnedParticipantId === mainParticipant.id;

	const [showControls, setShowControls] = useState(false);

	const [isCCEnabled, setIsCCEnabled] = useState(isHelloParticipant ? mainParticipant.isCCEnabled : false);

	const toggleTrack = async trackType => {
		const response = await mainParticipant.remoteTrackController.toggle(trackType);

		if (response.deviceControlsLocked) {
			conferenceConfigs.setDeviceControlsLockedNotification({ isShowing: true });
			return;
		}
		setShowControls(false);
	};

	const togglePinnedParticipant = () => {
		conferenceConfigs.setPinnedParticipantId(isCurrentlyPinnedParticipant ? '' : mainParticipant.id);
	};

	const showLiveCaptionsButton = () =>
		isHelloParticipant &&
		getSomeRoleConfigurationsValues(conferenceConfigs.roundingConfigurations, [RoundingSettings.LiveCaptions]);

	const onToggleCC = isEnabled => {
		if (isHelloParticipant) {
			mainParticipant.toggleCC(isEnabled);
		}
	};

	const onRemoveParticipant = participantId => {
		if (mainParticipant instanceof LocalParticipant || !localParticipant.isOwner) {
			return;
		}
		conferenceConfigs.setRemoveParticipantModal({
			isOpen: true,
			modalMessage: `${intl.formatMessage({ id: 'confirmationOfRemovalParticipant' }, { value: mainParticipant.name })}`,
			onSubmit: () => {
				if (!localParticipant.isOwner) {
					return;
				}
				conference.removeParticipant(participantId);
				conferenceConfigs.setRemoveParticipantModal({
					isOpen: false,
					message: '',
					onSubmit: () => {},
				});
			},
		});
	};

	const makeHost = async participantId => {
		const response = await conference.transferOwnership(participantId);
		if (!response) {
			conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
			return;
		}
		localParticipant.isGuest = true;
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, false);
		ClosingOwnerViews.forEach(({ action, value }) => {
			conferenceConfigs.onConfigurationToggleAction(action, value ?? null);
			if (action === ControlsActions.TOGGLE_LIVE_EXAMINATIONS) {
				socketFunctions.toggleHealthData({
					isEnabled: false,
					helloDeviceId: participantId.objectId,
					conferenceId: conference.conferenceId,
					participantId: localParticipant.id,
					toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
					measurementType: '',
				});
			}
		});
	};

	useEffect(() => {
		return mainParticipant.on(event => {
			if (event instanceof CallsParticipant.CCStateChanged) {
				setIsCCEnabled(event.isCCEnabled);
			}
		});
	}, [mainParticipant]);

	return (
		<StyledParticipantActions $isDarkMode={conferenceConfigs.isDarkMode}>
			<StyledMainParticipantInfo>
				{isCurrentlyPinnedParticipant && <PinIcon color={LightTheme.colors.grayZero} width={22} height={22} />}
				<StyledParticipantInfo>
					{!tracks[Mic] && <IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />}
					{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
				</StyledParticipantInfo>
				<p>
					{mainParticipant instanceof LocalParticipant ? translate('you') : mainParticipant.name}
					{tracks[ScreenShare] && activeTrackType === ScreenShare && ` (${intl.formatMessage({ id: 'presenting' })})`}
				</p>
			</StyledMainParticipantInfo>
			<Dropdown isOpen={showControls} onClickOutside={() => setShowControls(false)}>
				<Dropdown.Button onClick={() => setShowControls(prevState => !prevState)} />
				<Dropdown.Items renderIntoPortal={true}>
					<List>
						{isHelloParticipant && !localParticipant.isGuest && (
							<>
								<List.Item onClick={() => toggleTrack(Cam)}>
									{tracks[Cam] && (
										<VideocamIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
									)}
									{!tracks[Cam] && (
										<VideocamOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
									)}
									{translate(tracks[Cam] ? 'cameraOff' : 'cameraOn')}
								</List.Item>
								<List.Item onClick={() => toggleTrack(Mic)}>
									{tracks[Mic] && (
										<VolumeUpIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
									)}
									{!tracks[Mic] && (
										<VolumeOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
									)}
									{translate(tracks[Mic] ? 'muteAudio' : 'unMuteAudio')}
								</List.Item>
							</>
						)}
						<List.Item
							onClick={() => {
								togglePinnedParticipant();
								setShowControls(false);
							}}>
							{isCurrentlyPinnedParticipant && (
								<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
							)}
							{!isCurrentlyPinnedParticipant && (
								<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
							)}
							{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
						</List.Item>
						{!(mainParticipant instanceof LocalParticipant) && localParticipant.isOwner && (
							<List.Item onClick={() => onRemoveParticipant(mainParticipant.id)}>
								<Icon name='close' size={18} />
								{translate('removeParticipant')}
							</List.Item>
						)}
						{showLiveCaptionsButton() && (
							<List.Item
								onClick={() => {
									onToggleCC(!isCCEnabled);
									setShowControls(false);
								}}>
								{isCCEnabled && (
									<LiveCaptionsIcon height={18} width={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
								)}
								{!isCCEnabled && (
									<LiveCaptionsDisabledIcon height={18} width={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
								)}
								{translate(isCCEnabled ? 'disableLiveCaptions' : 'enableLiveCaptions')}
							</List.Item>
						)}
						{!isHelloParticipant &&
							[UserTypes.DOCTOR, UserTypes.NURSE].includes(mainParticipant?.role) &&
							localParticipant.isOwner && (
								<List.Item
									onClick={() => {
										makeHost(mainParticipant.id);
										setShowControls(false);
									}}>
									<TransferOwnershipIcon height={18} width={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
									{translate('makeHost')}
								</List.Item>
							)}
					</List>
				</Dropdown.Items>
			</Dropdown>
		</StyledParticipantActions>
	);
};
export default MainParticipantControls;
