const MobileLaptop = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={17} viewBox='0 0 22 17' fill='none' {...props}>
		<path
			d='M14.2988 5.97461C14.2988 5.42232 14.7465 4.97461 15.2988 4.97461H19.8988C20.4511 4.97461 20.8988 5.42232 20.8988 5.97461V14.9746C20.8988 15.5269 20.4511 15.9746 19.8988 15.9746H15.2988C14.7465 15.9746 14.2988 15.5269 14.2988 14.9746V5.97461Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
		<path
			d='M19.7996 4.97539V5.72539C20.2138 5.72539 20.5496 5.3896 20.5496 4.97539H19.7996ZM14.3149 15.1504V15.9004C14.5366 15.9004 14.7469 15.8023 14.8894 15.6324C15.0319 15.4626 15.092 15.2384 15.0535 15.02L14.3149 15.1504ZM1.09961 12.4004V11.6504C0.685396 11.6504 0.349609 11.9862 0.349609 12.4004H1.09961ZM2.19961 12.4004V13.1504C2.61382 13.1504 2.94961 12.8146 2.94961 12.4004H2.19961ZM18.1496 0.650391H3.84961V2.15039H18.1496V0.650391ZM20.5496 3.05039C20.5496 1.72491 19.4751 0.650391 18.1496 0.650391V2.15039C18.6467 2.15039 19.0496 2.55333 19.0496 3.05039H20.5496ZM20.5496 4.97539V3.05039H19.0496V4.97539H20.5496ZM15.2996 5.72539H19.7996V4.22539H15.2996V5.72539ZM15.0496 5.97539C15.0496 5.83732 15.1615 5.72539 15.2996 5.72539V4.22539C14.3331 4.22539 13.5496 5.00889 13.5496 5.97539H15.0496ZM15.0496 14.9754V5.97539H13.5496V14.9754H15.0496ZM15.0535 15.02C15.051 15.0061 15.0496 14.9912 15.0496 14.9754H13.5496C13.5496 15.079 13.5587 15.1811 13.5763 15.2808L15.0535 15.02ZM2.19961 15.9004H14.3149V14.4004H2.19961V15.9004ZM0.349609 14.0504C0.349609 15.0721 1.17788 15.9004 2.19961 15.9004V14.4004C2.00631 14.4004 1.84961 14.2437 1.84961 14.0504H0.349609ZM0.349609 12.4004V14.0504H1.84961V12.4004H0.349609ZM2.19961 11.6504H1.09961V13.1504H2.19961V11.6504ZM1.44961 3.05039V12.4004H2.94961V3.05039H1.44961ZM3.84961 0.650391C2.52413 0.650391 1.44961 1.72491 1.44961 3.05039H2.94961C2.94961 2.55333 3.35255 2.15039 3.84961 2.15039V0.650391Z'
			fill='currentColor'
		/>
		<path d='M2.19922 12.4004H14.2992' stroke='#currentColor' strokeWidth={1.5} />
		<path
			d='M18.1488 13.7746C18.1488 14.0784 17.9026 14.3246 17.5988 14.3246C17.2951 14.3246 17.0488 14.0784 17.0488 13.7746C17.0488 13.4709 17.2951 13.2246 17.5988 13.2246C17.9026 13.2246 18.1488 13.4709 18.1488 13.7746Z'
			stroke='currentColor'
			strokeWidth={1.5}
		/>
	</svg>
);
export default MobileLaptop;
