import { useEffect, useState } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference } from 'calls/hooks/index.js';
import { getInterpreterCallTypeOptions } from 'calls/helpers/index.js';
import { ButtonType, InterpreterCallTypes } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { languages } from 'constants/languageLine.js';
import { dialOutTranslator } from 'api/sipProvider.js';
import CustomSelect from 'components/Select.jsx';

/**
 * @param {object} props
 * @param {object} props.interpretationConfig
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {(participantId: string, message: string) => void} props.removeParticipantOnNoAnswer
 */

const InviteLanguageLineView = ({ interpretationConfig, onDismiss, setError, removeParticipantOnNoAnswer }) => {
	const intl = useIntl();
	const callTypes = getInterpreterCallTypeOptions(intl);
	const conference = useConference();
	const [selectedLanguageLine, setSelectedLanguageLine] = useState({
		label: '',
		value: '',
		index: 999,
	});
	const [patientPreferredLanguage, setPatientPreferredLanguage] = useState(null);
	const [selectedCallTypeId, setSelectedCallTypeId] = useState(
		interpretationConfig?.callType
			? callTypes.find(item => interpretationConfig.callType === item.value).value
			: callTypes[0].value
	);

	useEffect(() => {
		const patientInfo = conference.additionalData.find(item => item.key === 'patientInfo');
		if (patientInfo) {
			const { preferredLanguage } = patientInfo.value;
			setPatientPreferredLanguage(preferredLanguage);
			const foundLanguage = languages.find(
				item => preferredLanguage && item.label.toLowerCase().startsWith(preferredLanguage.toLowerCase())
			);
			if (!foundLanguage) {
				return;
			}
			setSelectedLanguageLine(foundLanguage);
		}
	}, []);

	const getLanguageLineNumber = () => {
		const defaultNumber = 'test-audio-siptestlls@sip.languageline.com';
		return interpretationConfig.authCode === defaultNumber
			? defaultNumber
			: selectedLanguageLine.value.replace('YOUR-AUTHCODE-HERE', interpretationConfig.authCode);
	};

	const onLanguageLineSelect = event => {
		const foundItem = languages.find(language => language.value === event.target.value);
		if (!foundItem) {
			return;
		}
		setSelectedLanguageLine(foundItem);
	};

	const inviteDialOutTranslator = async () => {
		if (interpretationConfig.authCode && !selectedLanguageLine.value) {
			return;
		}
		const numberToDial = getLanguageLineNumber().replace(selectedCallTypeId === InterpreterCallTypes.VIDEO ? '-audio' : '', '');
		const response = await dialOutTranslator(
			numberToDial,
			conference.conferenceId,
			selectedCallTypeId === InterpreterCallTypes.AUDIO
		);
		if (response.error) {
			setError(translate('serviceProvicerNoAnswer'));
			return;
		}
		removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
		onDismiss();
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					{patientPreferredLanguage && (
						<div className='bottom-20'>
							{translate('patientPreferredLanguage')}: {patientPreferredLanguage}
						</div>
					)}
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={`${intl.formatMessage({ id: 'interpreter' })} ${intl.formatMessage({ id: 'language' })}`}
							name='selectLanguage'
							items={languages}
							valueField='value'
							textField='label'
							placeholder='Select Language'
							value={selectedLanguageLine.value}
							onSelect={onLanguageLineSelect}
						/>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={intl.formatMessage({ id: 'callType' })}
							name='selectCallType'
							items={callTypes}
							valueField='value'
							textField='label'
							placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'callType' })}`}
							value={selectedCallTypeId}
							onSelect={event => setSelectedCallTypeId(+event.target.value)}
						/>
					</div>
					<div className='interpreter-desc'>
						<p>{translate('languageLineDesc')}</p>
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button
					type='submit'
					onClick={inviteDialOutTranslator}
					variant={ButtonType.SUBMIT}
					disabled={!selectedLanguageLine.value}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteLanguageLineView;
