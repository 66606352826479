import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import EmptyState from 'components/EmptyState.jsx';
import { formattedDate, getTimeDuration } from 'infrastructure/helpers/dateHelper.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { MedicalInfoTypes, UserRoles } from 'constants/enums.js';
import { getVisitDetails, getPatientVisits, getVisits } from 'api/medicalInfo.js';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import AddEditPrescription from 'containers/Prescriptions/AddEditPrescription.jsx';
import Modal from 'components/Modal.jsx';
import Button from 'components/Button.jsx';
import { Form } from 'components/index.js';

const Visits = props => {
	const intl = useIntl();
	const [visits, setVisits] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pageIndex, setPageIndex] = useState(0);
	const [selectedVisit, setSelectedVisit] = useState(null);
	const [shouldShowAddEdit, setShouldShowAddEdit] = useState(false);
	const [selectedMedicine, setSelectedMedicine] = useState(null);
	const [isReasonShown, setIsReasonShown] = useState(false);
	const [duration, setDuration] = useState(null);
	const hasReachedEnd = useRef(null);

	useEffect(() => {
		const getAllVisits = async () => {
			setIsLoading(true);
			const params = {
				pageIndex,
				userId: getUserId(),
				patientId: props.selectedPatient?.id,
				role: getUserRole(),
			};
			const response = await getVisits(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setVisits(prevState => (pageIndex === 0 ? response.medicalVisits : [...prevState, ...response.medicalVisits]));
				hasReachedEnd.current = response.length < 10;
			}
			setIsLoading(false);
		};

		const getAllPatientVisits = async () => {
			const params = {
				pageIndex,
				userId: getUserId(),
				patientId: props.selectedPatient?.id,
			};
			const response = await getPatientVisits(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setVisits(prevState => (pageIndex === 0 ? response.medicalVisits : [...prevState, ...response.medicalVisits]));
				hasReachedEnd.current = response.medicalVisits.length < 10;
			}
			setIsLoading(false);
		};
		if (getUserRole() === UserRoles.DOCTOR) {
			getAllPatientVisits();
			return;
		}
		getAllVisits();
	}, [pageIndex, props.selectedPatient, shouldShowAddEdit]);

	const medicalItem = (prescription, type, optionalText) => (
		<div>
			<h5>{translate(type)}</h5>
			<p>
				{prescription[type]} {optionalText && <span>{optionalText}</span>}
			</p>
		</div>
	);

	const setEditPrescriptionModal = (prescription, hasReason = false) => {
		setSelectedMedicine(prescription);
		setIsReasonShown(hasReason);
		setShouldShowAddEdit(true);
	};

	const fetchVisitDetails = async visitId => {
		const params = {
			pageIndex,
			userId: getUserId(),
			patientId: props.selectedPatient?.id,
			visitId,
		};
		const response = await getVisitDetails(params);

		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSelectedVisit(response.medicalVisit);
	};

	const calculateDuration = visit =>
		visit.duration ||
		getTimeDuration((new Date(visit.endedAt).getTime() - new Date(visit.startedAt).getTime()) / 1000) ||
		'00:00:00';

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<>
			{!isLoading && (
				<>
					{visits.length > 0 && !selectedVisit && (
						<div className='patient-summary-wrapper visits-wrapper'>
							<h3 className='text-align-left'>{translate('visits')}</h3>
							<div className='patient-summary-table-wrapper' onScroll={handleScroll}>
								<table cellPadding='0' cellSpacing='0'>
									<thead>
										<tr>
											<th>{translate('doctor')}</th>
											<th>{translate('dateAndTime')}</th>
											<th>{translate('lengthOfVisit')}</th>
											<th />
										</tr>
									</thead>
									<tbody>
										{visits.map(visit => {
											const duration = calculateDuration(visit);
											return (
												<tr key={visit.id}>
													<td>{visit.performerName}</td>
													<td>{formattedDate(visit.startedAt)}</td>
													<td>{duration}</td>
													<td>
														<Button
															className='button alert-view-details-btn'
															text={translate('viewDetails')}
															onClick={() => {
																setDuration(duration);
																fetchVisitDetails(visit.id);
															}}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					)}
					{visits.length === 0 && (
						<div className='empty-state-wrapper visits-wrapper'>
							<EmptyState title={translate('noVisitsFound')} image='Visits.svg' />
						</div>
					)}
					{selectedVisit && (
						<div className='patient-summary-wrapper visits-wrapper'>
							<h3 className='text-align-left flex flex-align-center'>
								<i onClick={() => setSelectedVisit(null)} className='material-icons-outlined cursor-pointer'>
									west
								</i>
								{translate('visitWith')} {selectedVisit.patient.firstName} {selectedVisit.patient.lastName}
							</h3>
							<div className='call-wrapup-wrapper'>
								<div className='visits-main-details'>
									<h3>{translate('mainDetails')}</h3>
									<div className='flex flex-space-between text-align-left left-align-items'>
										<div>
											<p>{translate('doctor')}</p>
											<div className='flex flex-align-center'>
												<ProfilePicture
													profilePicture={selectedVisit.performer.profilePicture}
													className='doctor-request-img available-doctor-request-img'
													fullName={`${selectedVisit.performer.firstName} ${selectedVisit.performer.lastName}`}
												/>

												<div>
													<p className='bold'>
														{selectedVisit.performer.firstName} {selectedVisit.performer.lastName}
													</p>
													<p>{translate('familyPhysician')}</p>
												</div>
											</div>
										</div>
										<div>
											<p>{translate('dateAndTime')}</p>
											<p>{formattedDate(selectedVisit.startedAt)}</p>
										</div>
										<div>
											<p>{translate('lengthOfVisit')}</p>
											<p>{duration}</p>
										</div>
									</div>
								</div>

								<div>
									<div className='flex flex-space-between'>
										<h3>{translate('prescriptions')}</h3>
										{selectedVisit.performer.id === getUserId() && (
											<Button
												text={translate('addPrescription')}
												variant='white'
												onClick={() => {
													setEditPrescriptionModal(null);
													setSelectedMedicine({
														dispenseAsWritten: false,
													});
												}}
											/>
										)}
									</div>

									<div className='notes-inner-wrapper'>
										{selectedVisit?.prescriptions?.map(prescription => (
											<div className='simple-txt-modal-inner prescriptions-modal'>
												<div
													className={classNames('prescription-details', prescription.isDisabled ? 'disabled-prescription' : '')}>
													<div key={prescription.id} className='flex flex-space-between'>
														<h4>{`${prescription.medicine.name} ${prescription.medicine.strengthAndForm}`}</h4>
														{!prescription.isCompleted && !prescription.isDisabled && (
															<div className='flex-end prescription-actions'>
																<i
																	className='material-icons cursor-pointer'
																	onClick={() => setEditPrescriptionModal(prescription, true)}>
																	edit
																</i>
															</div>
														)}
													</div>
													<div className='flex'>
														{medicalItem(prescription, 'dosage', translate('capsule'))}
														{medicalItem(prescription, 'frequency', translate('timesAday'))}
													</div>
													{medicalItem(prescription, 'duration', translate('days'))}
													<div className='flex'>
														{medicalItem(prescription, 'quantityToDispense')}
														{medicalItem(prescription, 'refills')}
													</div>
													<div className='flex'>
														{medicalItem(prescription, 'patientInstructions')}
														{medicalItem(prescription, 'noteToPharmacist')}
													</div>
													{prescription.dispenseAsWritten && (
														<div>
															<h5>{translate('dispensedAsWritten')}</h5>
														</div>
													)}
												</div>
											</div>
										))}

										{(!selectedVisit.prescriptions || selectedVisit?.prescriptions?.length === 0) && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.PRESCRIPTIONS }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
								<div>
									<h3>{translate('notes')}</h3>
									<div className='notes-inner-wrapper'>
										<div className='flex flex-wrap'>
											{selectedVisit.notes &&
												selectedVisit?.notes?.map(item => (
													<div>
														<p>{item.title}</p>
														<p>{item.text}</p>
													</div>
												))}
										</div>
										{(!selectedVisit.notes || selectedVisit?.notes?.length === 0) && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: 'visits' }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
								<div>
									<h3>{translate('diagnoses')}</h3>
									<div>
										<div className='flex flex-wrap'>
											{selectedVisit.diagnoses &&
												selectedVisit?.diagnoses?.map(diagnose => (
													<div key={diagnose.id}>
														<p>{diagnose.description}</p>
														<p>{diagnose.code}</p>
													</div>
												))}
										</div>
										{(!selectedVisit.diagnoses || selectedVisit?.diagnoses?.length === 0) && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.DIAGNOSES }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
								<div>
									<h3>{translate('procedures')}</h3>
									<div>
										<div className='flex flex-wrap'>
											{selectedVisit.procedures &&
												selectedVisit?.procedures?.map(procedure => (
													<div key={procedure.id}>
														<p>{procedure.description}</p>
														<p>{procedure.code}</p>
													</div>
												))}
										</div>
										{(!selectedVisit.procedures || selectedVisit?.procedures?.length === 0) && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.PROCEDURES }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}
			{shouldShowAddEdit && (
				<Modal
					display={shouldShowAddEdit}
					position='center'
					className='standard-modal-wrapper modal-wrapper-wo-btn call-wrap-up-modal-wrapper'
					onModalClose={() => setShouldShowAddEdit(null)}>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner simple-txt-modal-inner prescriptions-modal call-wrap-up-modal call-wrap-up-prescriptions'>
						<Form height={600}>
							<AddEditPrescription
								visitId={selectedVisit.id}
								selectedMedicine={selectedMedicine}
								type={MedicalInfoTypes.PRESCRIPTIONS}
								prescriptions={selectedVisit?.prescriptions}
								setMedicalInfo={item => setSelectedVisit(prevState => ({ ...prevState, prescriptions: item }))}
								closePrescriptions={() => setShouldShowAddEdit(false)}
								setSelectedMedicine={setSelectedMedicine}
								isReasonShown={isReasonShown}
								isOffCall={true}
							/>
						</Form>
					</div>
				</Modal>
			)}
			{isLoading && (
				<Grid rows='auto' width='100%' stretch='100vh' vertAlign='center' horizAlign='center'>
					<Loader />
				</Grid>
			)}
		</>
	);
};

export default Visits;
