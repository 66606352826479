import React, { Suspense } from 'react';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Grid } from 'components/index.js';
import { lazyWithRetry } from 'infrastructure/helpers/commonHelpers.js';
const Visitors = lazyWithRetry(() => import('views/Visitors.jsx'));

const VisitorsWrapper = () => (
	<Suspense
		fallback={
			<MainLayout isMonitoring={true}>
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			</MainLayout>
		}>
		<Visitors />
	</Suspense>
);

export default VisitorsWrapper;
