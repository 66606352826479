import { Cam, Mic, ScreenAudio, ScreenShare } from '@solaborate/calls/webrtc';
import { UserRoles, ControlsActions } from 'calls/enums/index.js';
import { getUserRole } from 'infrastructure/auth.js';

export const countriesData = {
	KOS: {
		name: 'Kosovo',
		code: 'KOS',
		phonePrefix: '+383',
		phonePattern: '+{383} 00 000-000',
		flag: '🇽🇰',
	},
	US: {
		name: 'United States',
		code: 'US',
		phonePrefix: '+1',
		phonePattern: '+{1} (000) 000-0000',
		flag: '🇺🇸',
	},
};

/**
 * @type {import('@solaborate/calls/webrtc').Constraints}
 */
export const defaultConstraints = {
	[Mic]: true,
	[Cam]: {
		width: { ideal: 1280 },
		height: { ideal: 720 },
	},
	[ScreenAudio]: true,
	[ScreenShare]: {
		width: { ideal: 1920 },
		height: { ideal: 1080 },
	},
};

export const MedicalControlsActions = [
	ControlsActions.TOGGLE_HEALTH_MEASUREMENTS,
	ControlsActions.TOGGLE_CAMERA_MEASUREMENTS,
	ControlsActions.TOGGLE_PATIENT_HISTORY,
	ControlsActions.TOGGLE_LIVE_EXAMINATIONS,
	ControlsActions.TOGGLE_TELEMETRY,
	ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL,
	ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL,
	ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL,
	ControlsActions.TOGGLE_MORE_PARTICIPANTS,
	ControlsActions.TOGGLE_WHITEBOARD,
	ControlsActions.TOGGLE_AI,
	ControlsActions.TOGGLE_ROOM_SIGN,
];

export const PatientMeasurementsActions = [
	...MedicalControlsActions,
	ControlsActions.TOGGLE_DIAGNOSES,
	ControlsActions.TOGGLE_PARTICIPANTS_VIEW,
	ControlsActions.TOGGLE_CARE_EVENTS,
];

export const CareEventsActions = [...MedicalControlsActions, ControlsActions.TOGGLE_CARE_EVENTS];

export const ClosingOwnerViews = [
	{ action: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false },
	{ action: ControlsActions.TOGGLE_TELEMETRY, value: false },
	{ action: ControlsActions.TOGGLE_CARE_EVENTS, value: false },
	{ action: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false },
	{ action: ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL, value: false },
	getUserRole() === UserRoles.DOCTOR && { action: ControlsActions.TOGGLE_DIAGNOSES, value: false },
];

export const CallControlHeight = 70;
